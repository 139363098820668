import React, {  useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
import { Loaderr } from "../../../components/Loaderr";
import useFormApiHelper from "../../../utils/useFormApiHelper";
import debounce from "lodash/debounce";
import { Spinner } from "../../../assets/images/three-dots-loading";
import { SettingPageForLeadForm } from "./SettingPageForLeadForm";
import { SettingPageForOpportunityForm } from "./SettingPageForOpportunityForm";
import  RedirectionSettingPage  from "./RedirectionSettingPage";

const UPDATE_FORM_SETTINGS = gql`
mutation UpdateFormSettings($teamId: String!, $formId: String, $formDescription: String) {
  UpdateFormSettings(team_id: $teamId, form_id: $formId, form_description: $formDescription) {
    team_id
    form_id
  }
}`;
 
export default function SettingsPage() {
  const { GetFormData } = useFormApiHelper();
  const params = useParams();
  const { formDataLoading, formData } = GetFormData(params);
  const [formLoader, setFormLoader] = useState(false);

  const [description, setDescription] = useState("");
  const [lastSavedDescription, setLastSavedDescription] = useState("");
  const [updateFormSetting, { loading: updateFormLoading }] = useMutation(UPDATE_FORM_SETTINGS);

  // Debounced function to update form settings
  const debouncedUpdate = useCallback(
    debounce((desc) => {
      updateFormSetting({
        variables: {
          teamId: String(formData?.formData?.team_id),
          formId: params.id,
          formDescription: desc,
        },
      }).then(() => {
        setLastSavedDescription(desc); // Update the saved description only after successful API call
      });
    }, 500),
    [updateFormSetting, params.id] // Ensure dependencies are stable
  );
  // Trigger debounced function only if the value has changed
  useEffect(() => {
    if (description?.trim() !== "" && description !== lastSavedDescription) {
      debouncedUpdate(description);
    }
    return () => debouncedUpdate.cancel(); // Cleanup debounce on unmount
  }, [description, debouncedUpdate, lastSavedDescription]);

  // Populate initial description from formData
  useEffect(() => {
    setDescription(formData?.formData?.form_description);
    setLastSavedDescription(formData?.formData?.form_description); // Initialize the saved description
  }, [formData]);

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
//console.log(formData?.formData?.form_type)
  const renderSaveStatus = () => {
    return  updateFormLoading || formLoader ? (
      <div className="react-loader" style={{ width: "20px", height: "20px",marginLeft: "10px",color: "blue" }}>
        {Spinner}
      </div>
    ) : (
      <span
        className="d-flex align-items-center"
        style={{ fontSize: "11px", color: "green" }}
      >
        <i className="bx bx-check-double fs-4"></i>
        <span className="d-none d-lg-block">Form Saved</span>
      </span>
    );
  };
  function formatString(str) {
    if (!str) return ""; // Handle undefined or null gracefully
    return str
      .toLowerCase() // Ensure uniform case
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
  }
  

  if (formDataLoading) return <Loaderr />;

  return (
    <div className="main fmc-main">
      <section className="fm-condition">
        <section className="col-xxl-5 col-lg-6 col-md-9 mb-5 pb-5 col-12 mx-auto">
          <div className="d-flex flex-wrap justify-content-between align-items-center mb-2 py-4">
            <h5 className="m-0">
              <i className="iconify me-1 nav_icon" data-icon="fa-solid:cog" /> Settings
            </h5>
          </div>

          <div className="mx-auto bg-white d-flex flex-column flex-wrap rounded-3 border position-relative mb-5 p-4">
            <div className="w-100 d-flex justify-content-between m-0 mb-3">
              <h5 className="fs-6 m-0 mb-4">Form Settings</h5>
              <span>{renderSaveStatus()}</span>
            </div>

            <div className="mb-3 disable-default-field-div">
              <label className="mb-2 d-flex align-items-center">
                <i className="iconify fs-6 me-2 nav_icon" data-icon="tdesign:form" />
                Form Type
              </label>
              <select className="form-control form-select input-bn search-fgy mb-3 disable-default-field">
                <option value="">{formData?.formData?.form_type}</option>
              </select>
            </div>

         { formData?.formData?.form_type=== "Lead Forms" &&  <div className="mb-3 disable-default-field-div">
              <label className="mb-2 d-flex align-items-center">
                <i className="iconify fs-6 me-2 nav_icon" data-icon="tdesign:form" />
               Lead Form Type
              </label>
              <select className="form-control form-select input-bn search-fgy mb-3 disable-default-field">
                <option value="">{formatString(formData?.formData?.lead_form_type)}</option>
              </select>
            </div>
}


            <div className="mb-3">
              <label>
                <span className="d-flex align-items-center">
                  <i className="iconify me-1 mr-1 fs-5" data-icon="mdi:text" />
                  <span>Form Description</span>
                </span>
              </label>
              <textarea
                className="form-control input-bn"
                placeholder="Form Description"
                value={description}
                onChange={handleDescriptionChange}
              />
            </div>
            { formData?.formData?.form_type === "Opportunity Forms" && <SettingPageForOpportunityForm GetFormData={formData?.formData} setFormLoader={setFormLoader} />}



          </div>
        {formData?.formData?.form_type === "Lead Forms" && <SettingPageForLeadForm GetFormData={formData?.formData} setFormLoader={setFormLoader} />}
        {formData?.formData?.form_type === "Opportunity Forms" && <RedirectionSettingPage  GetFormData={formData?.formData} formFields={formData?.formData.form_fields} setFormLoader={setFormLoader}/> }

        </section>
          

      </section>
    </div>
  );
}