import React from "react";
import { useState, useEffect } from "react";
import { ModalFooter } from "./ModalFooter";

const CheckboxSettings = ({
  elementid,
  board,
  maxLengthofLabel,
  handleCloseModalClick,
  elementSettingsData,
}) => {
  const ElementName = board?.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.name;
  const initialvalue = {
    options: [],
    label: ElementName,
    size: "large",
    inputType: "text",
    required: false,
    minValue: "",
    maxValue: "",
    instructions: "",
  };

  const [Checkbox, setCheckbox] = useState(
    Object.keys(elementSettingsData)?.length !== 0
      ? elementSettingsData
      : initialvalue
  );
  const showingFieldId = /\d/.test(elementid);

  const [showErrorInFooter, setshowErrorInFooter] = useState(true);

  const handlerAddNewOption = () => {
    setshowErrorInFooter(true);
    const id = Math.floor(Math.random() * 1000);
    const values = [...Checkbox.options];
    values.push({ id: id, value: "", isCheck: false });
    setCheckbox({ ...Checkbox, options: values });
  };
  const handleInputChange = (e) => {
    const name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (String(name) === "label" && value?.trim() === "") {
      value = ElementName; // Set the default value to the element name
    }
    setCheckbox({ ...Checkbox, [name]: value });
  };
  const handleChangeInAddOption = (event, index) => {
    const { name, value } = event.target;

    const updatedOptions = [...Checkbox.options];

    updatedOptions[index] = {
      ...updatedOptions[index],
      [name]: value,
    };

    setCheckbox({ ...Checkbox, options: updatedOptions });
  };
  const handleChangeInAdd = (event) => {
    if (event.target.value) {
      setshowErrorInFooter(false);
    } else {
      setshowErrorInFooter(true);
    }
  };

  useEffect(() => {
    const data = [...Checkbox.options];
    data?.some((item) => {
      if (+data?.length === 0) {
        return setshowErrorInFooter(false);
      } else if (item?.value === "") {
        return setshowErrorInFooter(true);
      } else {
        return setshowErrorInFooter(false);
      }
    });
    //console.log("check",check)
  }, [Checkbox.options]);

  const handleDeleteForOptions = (event, id) => {
    event.preventDefault();
    const values = [...Checkbox.options];
    // const newboard = values.filter((item) => item.id !== id);
    values.splice(id, 1);
    setCheckbox({ ...Checkbox, options: values });
  };

  return (
    <>
      <div className="modal-body py-4 px-lg-5 p-md-4">
        <h5 className="mb-4">
          <i
            className="iconify fs-5 pointer me-1"
            data-icon="tabler:checkbox"
          />
          Checkbox Field
        </h5>
        <div className="mb-4">
          <label>
            <span className="d-flex mb-2 align-items-center">
              <span>Checkbox-Field label</span>
            </span>
          </label>
          <input
            className="form-control input-bn"
            type="text"
            name="label"
            maxLength="100"
            minLength={maxLengthofLabel}
            defaultValue={Checkbox?.label || ""}
            onBlur={handleInputChange}
            placeholder="Enter Checkbox-Field label"
          />
        </div>

        {Object.keys(Checkbox?.options)?.length > 0 ? (
          Checkbox?.options?.map((input, index) => {
            return (
              <div key={input?.id}>
                <div className="mb-2">
                  <div className="d-flex align-items-center">
                    <i
                      className="iconify mx-1 pointer text-secondary fs-5"
                      data-icon="ci:grid-vertical"
                    />
                    <input
                      className="form-control input-bn"
                      type="text"
                      defaultValue={input?.value || ""}
                      id={input?.id}
                      name="value"
                      placeholder="Enter option"
                      onBlur={(event) => handleChangeInAddOption(event, index)}
                      onChange={(event) => handleChangeInAdd(event)}
                    />
                    <span onClick={(e) => handleDeleteForOptions(e, index)}>
                      <i
                        className="iconify fs-5 text-danger pointer ms-2"
                        data-icon="mdi:trash"
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )}

        <div className="d-flex align-items-center">
          <i
            className="iconify fs-5 pointer text-secondary mx-1"
            data-icon="ci:plus"
          />
          <div
            className="btn btn-primary rounded-pill "
            onClick={() => {
              handlerAddNewOption();
            }}
          >
            Add new option
          </div>
        </div>
        <hr className="hr my-5" />
        <h5 className="mb-4">
          <i className="iconify fs-5 pointer me-1" data-icon="bx:slider" />
          General Settings
        </h5>
        {/* dropdowns----------- */}
        <div className="mt-4">
          <div className="mb-4">
            <label className="w-100">
              <span className=" mb-2 d-flex justify-content-between align-items-center">
                <span className="me-2">Size</span>
              </span>
            </label>
            <select
              className="form-select input-bn"
              value={Checkbox?.size || ""}
              name="size"
              onChange={handleInputChange}
            >
              <option value="large">Large</option>
              <option value="medium">Medium</option>
              <option value="small">Small</option>
            </select>
          </div>
        </div>
        {/* dropdowns----------- */}
        <div className="mt-4">
          <div className="mb-4">
            <label className="w-100">
              <span className=" mb-2 d-flex justify-content-between align-items-center">
                <span className="me-2">Input type</span>
              </span>
            </label>
            <select
              disabled="true"
              className="form-select input-bn"
              value={Checkbox?.inputType || ""}
              name="inputType"
              onChange={handleInputChange}
            >
              <option value="chekcbox">CheckBox</option>
              <option value="password">Password</option>
              <option value="numbers">Numbers</option>
            </select>
          </div>
        </div>
        <div className="mb-4">
          <span>Options</span>
          <div className="mt-2 d-flex align-items-center">
            <div className="me-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={Checkbox?.required || ""}
                onChange={handleInputChange}
                name="required"
                id="check-10"
              />
              <label className="form-check-label ms-1" htmlFor="check-10">
                Required
              </label>
            </div>
            <div className="me-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={Checkbox?.hidden || ""}
                onChange={handleInputChange}
                name="hidden"
                id="check-1"
              />
              <label className="form-check-label ms-1" htmlFor="check-1">
                Hidden
              </label>
            </div>
          </div>
        </div>

        <div className="mb-4">
          <div className="form-floating w-100 form-group">
            <textarea
              style={{ minHeight: "100px !important" }}
              defaultValue={Checkbox?.instructions || ""}
              name="instructions"
              onBlur={handleInputChange}
              placeholder="Instructions for Users (max 100 char)"
              className="form-control input-bn"
              type="text"
              maxLength="100"
            />
            <label>
              <span className="d-flex align-items-center">
                <i className="iconify me-1 mr-1 fs-5" data-icon="mdi:text" />
                <span>Instructions for Users (max 100 char)</span>
              </span>
            </label>
          </div>
        </div>
        {!showingFieldId && (
          <span className="h6">
            System Field Id : {showingFieldId ? null : `${elementid}`}
          </span>
        )}
      </div>
      <ModalFooter
        ElementSettingData={Checkbox}
        handleCloseModalClick={handleCloseModalClick}
        elementid={elementid}
        showErrorInFooter={showErrorInFooter}
      />
    </>
  );
};

export default CheckboxSettings;
