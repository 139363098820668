import React from "react";
import CustomSelectField from "../../components/fields/CustomSelectField";
import SingleselecCustom from "../../components/fields/SingleselecCustom";

const UpdatePersonDefaultFields = ({
  data_mapper,
  action_id,
  select_modal,
  selected_modal_id,
  field,
  customFieldsMapper,
  setCustomFieldsMapper,
}) => {
  const personIdMapper = [{ key: "personId", value: "personId", keyShow: "Person Id" }];
  const stageMapper = [{ key: "stage", value: "stage", keyShow: "Stage" }];
  const assignedUserIdMapper = [
    {
      key: "assignedUserId",
      value: "assignedUserId",
      keyShow: "Assigned User Id",
    },
  ];
  const assignedLenderIdMapper = [
    {
      key: "assignedLenderId",
      value: "assignedLenderId",
      keyShow: "Assigned Lender Id",
    },
  ];
  const backgroundMapper = [
    { key: "background", value: "background", keyShow: "Background" },
  ];

  const fieldOptions = (field) => {
    switch (field) {
      case "personId":
        return personIdMapper;
      case "stage":
        return stageMapper;
      case "assignedUserId":
        return assignedUserIdMapper;
      case "assignedLenderId":
        return assignedLenderIdMapper;
      case "background":
        return backgroundMapper;
      default:
        return [];
    }
  };

  const default_fields = customFieldsMapper.map((value, index) => {
    if (
      value.key === "stage" ||
      value.key === "assignedUserId" ||
      value.key === "assignedLenderId"
    ) {
      return (
        <fieldset
          key={value.uuid}
          className="border rounded-2 px-2 mb-3 bg-color-1"
        >
          <div className="row p-0 m-0">
            <div className="col-12 p-0 m-0 mt-3 row position-relative">
              <div className="col-6">
                <CustomSelectField
                  action_id={action_id}
                  select_modal={select_modal}
                  selected_modal_id=""
                  field_key={"key"}
                  field={field["defaultKey"]}
                  field_options={fieldOptions(value.key)}
                  text_value={customFieldsMapper[index]["key"]}
                  update_data_mapper={(key, value) => {
                    let temp = [...customFieldsMapper];
                    temp[index][key] = value;
                    setCustomFieldsMapper(temp);
                  }}
                  setCustomFieldsMapper={setCustomFieldsMapper}
                  data_mapper={data_mapper}
                />
              </div>
              <div className="col-6">
                <CustomSelectField
                  action_id={action_id}
                  select_modal={select_modal}
                  selected_modal_id={selected_modal_id}
                  field_key={"value"}
                  field={field["defaultValue"]}
                  field_options={field[value.key].options}
                  text_value={customFieldsMapper[index]["value"]}
                  update_data_mapper={(key, value) => {
                    let temp = [...customFieldsMapper];
                    temp[index][key] = value;
                    setCustomFieldsMapper(temp);
                  }}
                  setCustomFieldsMapper={setCustomFieldsMapper}
                />
              </div>
            </div>
          </div>
        </fieldset>
      );
    } else if (value.key === "background" || value.key === "personId") {
      return (
        <fieldset
          key={value.uuid}
          className="border rounded-2 px-2 mb-3 bg-color-1"
        >
          <div className="row p-0 m-0">
            <div className="col-12 p-0 m-0 mt-3 row position-relative">
              <div className="col-6">
                <CustomSelectField
                  action_id={action_id}
                  select_modal={select_modal}
                  selected_modal_id=""
                  field_key={"key"}
                  field={field["defaultKey"]}
                  field_options={fieldOptions(value.key)}
                  text_value={customFieldsMapper[index]["key"]}
                  update_data_mapper={(key, value) => {
                    let temp = [...customFieldsMapper];
                    temp[index][key] = value;
                    setCustomFieldsMapper(temp);
                  }}
                  setCustomFieldsMapper={setCustomFieldsMapper}
                  data_mapper={data_mapper}
                />
              </div>
              <div className="col-6">
                <SingleselecCustom
                  action_id={action_id}
                  select_modal={select_modal}
                  selected_modal_id={selected_modal_id}
                  field_key={"value"}
                  field={field["defaultValue"]}
                  field_options={[]}
                  text_value={customFieldsMapper[index]["value"]}
                  update_data_mapper={(key, value) => {
                    let temp = [...customFieldsMapper];
                    temp[index][key] = value;
                    setCustomFieldsMapper(temp);
                  }}
                  setCustomFieldsMapper={setCustomFieldsMapper}
                />
              </div>
            </div>
          </div>
        </fieldset>
      );
    }
  });

  return <>{default_fields}</>;
};
export default UpdatePersonDefaultFields;
