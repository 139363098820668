import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { Controller, useFormContext } from "react-hook-form";

export function EmailUi({
  elementid,

  showHide,
  oppFields,
  previewFlag = false,
}) {
  const { board } = useContext(boardConetxt);
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext({
    mode: "onFocus",
    reValidateMode: "onChange",
  });
  const disableElementForBuild = window.location.pathname.includes("build");

  useEffect(() => {
    if (oppFields) {
      setValue(elementid, oppFields);
    }
  }, [oppFields, elementid, setValue]);

  const ElementSettingsData = board?.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.settings;
  const {
    label,
    inputType,
    instructions,
    minValue,
    maxValue,
    // predefine,
    required,
    hidden,
  } = ElementSettingsData;

  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };
  const Elemlabel = board.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.name;

  const Location = useLocation();
 
     useEffect(() => {
       const params = new URLSearchParams(Location.search);
       const updatedParamValue = params.get(elementid);
     
       if (elementid && updatedParamValue) {
         setValue(elementid, updatedParamValue);
       }
     }, [elementid, setValue]);

  const checkingValidation = () => {
    if (Boolean(required) === true && String(showHide) === "show") {
      return false;
    } else if (Boolean(required) === false && String(showHide) === "show") {
      return false;
    } else if (Boolean(required) === true && String(showHide) === "hide") {
      return false;
    } else if (Boolean(required) === false && String(showHide) === "hide") {
      return false;
    } else if (Boolean(required) === true && hidden === true) {
      return false;
    } else if (Boolean(required) === true) {
      return true;
    } else {
      return false;
    }
  };
  const normalizeFieldName = (name) => {
    return name?.replace(/[.\s]/g, "_"); // Replace dots and spaces with underscores
  };
  return (
    <div
    className={`${(previewFlag && GetStyleClasses(elementid)) || ""} ${
      hidden && previewFlag ? "d-none" : ""
    }`}      id={elementid}
      style={initStyle}
      hidden={hidden && previewFlag}
    >
      <label>
        <span className="d-flex mb-2 align-items-center">
          <i className="iconify me-1 mr-1 fs-5" data-icon="carbon:email" />
          <span>
            {label === undefined || label === "" ? Elemlabel : label}

            {required ? <span style={{ color: "red" }}>*</span> : ""}
          </span>
        </span>
      </label>
      <div className="w-100 form-group">
        {Object?.keys(ElementSettingsData)?.length === 0 ? (
          <Controller
            control={control}
            name={normalizeFieldName(elementid)}
            rules={{
              maxLength: {
                value: 50,
                message: `Please Enter less then  30 maxValue Characters`,
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid Email Address",
              },
            }}
            render={({ field }) => (
              <input
                type="email"
                className="form-control input-bn"
                name="email"
                placeholder="Enter Email"
                disabled={disableElementForBuild}
                {...field}
              />
            )}
          />
        ) : (
          <Controller
            control={control}
            name={normalizeFieldName(elementid)}
            rules={{
              required: {
                value: checkingValidation(),
                message: "This field is required",
              },
              minLength: {
                value: String(minValue) === "" ? 0 : minValue,
                message: `Please Enter More then ${
                  String(minValue) === "" ? 0 : minValue
                } Characters`,
              },
              maxLength: {
                value: String(maxValue) === "" ? 50 : maxValue,
                message: `Please Enter less then ${
                  String(maxValue) === "" ? 50 : maxValue
                } Characters`,
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid Email Address",
              },
            }}
            render={({ field }) => (
              <input
                type={inputType ? inputType : "email"}
                className="form-control input-bn"
                name="email"
                // defaultValue={predefine === undefined ? "" : predefine}
                placeholder="Enter Email"
                disabled={disableElementForBuild}
                {...field}
              />
            )}
          />
        )}
      </div>
      <small className="text-secondary">
        {instructions ? instructions : ""}
      </small>

      <div className="d-flex flex-column gap-2">
        {errors && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>
    </div>
  );
}
