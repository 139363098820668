import  { useContext } from "react";
import { boardConetxt } from "../../../../pages/form-builder/dashboard/ContextHelper";

function GetStyleClasses(elementid) {
  const { board } = useContext(boardConetxt);
  const ElementSetting = board.find(
    (item) => String(item?.elementid )=== String(elementid)
  )?.settings;
  const { size  } = ElementSetting || {};

  let elementWidth = "col-12 mb-4 ";
  if (size === "large" || size === "left") {
    //elementFloat = "left";
    elementWidth = "col-md-12 mb-4 ";
  } else if (size === "medium" || size === "right") {
    //elementFloat = "right";
    elementWidth = "col-md-6 mb-4 ";
  } else if (size === "small" || size === "center") {
    //elementFloat = null;
    elementWidth = "col-md-4 mb-4 ";
  }

  return (elementWidth);
}

export default GetStyleClasses;
