import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Loaderr } from "../../../components/Loaderr";
import { gql, useQuery } from "@apollo/client";
import logo from "../../../assets/images/LOGO-BLUE.png";
import { useLocation } from "react-router-dom";

const FORM_NAME_QUERY = gql`
  query FormsNames {
  formsNames {
    form_id
    form_name
  }
}
`;

const OPPORTUNITY_DATA_QUERY = gql`
  query Opportunity($oppKey: String) {
    opportunity(opp_key: $oppKey) {
      opp_key
      sisu_client_updated_ts
      opp_updated_ts
      opp_stage
      opp_agreement_signed_date
      fub_deal_created_ts
      opp_type
      opp_appt_date
      fub_deal_stage_name
      opp_appt_met_date
      fub_person_id
      sisu_client_created_ts
      opp_created_ts
      fub_deal_id
      opp_appt_disposition
      teamFubDealId
      team
      fub_deal_entered_stage_ts
      sisu_client_id
      opp_assigned_osa
      opp_isa
      opp_notes
      opp_address
      opp_agreement_expiration_date
      appt_set_entry_id
      fub_appt_start_time
      fub_original_appt_start_time
      disp_text_wait_timestamp
      appt_set_lead_type
      appt_set_platform
      disp_text_original_wait_timestamp
      opp_address2
      opp_city
      opp_postal_code
      opp_last_name
      opp_state
      previous_opp_stage
      CreateEntryId
      pipeline_entry_id
      opp_forecasted_close_date
      opp_under_contract_date
      appt_form_id
      form_id_entry_id
      opp_settlement_date
      CreateFormId
      appt_outcome
      external_system_key_buyer
      otc_property_id
      external_system_key_seller
\      formid_entryids
      appt_form_entry_ids
      opp_price
      opp_commission_percent
      fub_appt_id
      stage_id
      pipeline_id
      opp_custom_fields
      custom_fields
      appt_form_entry_ids
    }
  }
`;

export const OpportunityDetailPage = () => {
  const { oppkey: oppKey } = useParams();
  const navigate = useNavigate();
  const [opportunity, setOpportunity] = useState(null);
    const Location = useLocation();
    const params = new URLSearchParams(Location.search);

  const { loading: opportunityLoading, data } = useQuery(OPPORTUNITY_DATA_QUERY, {
    variables: { oppKey },
  });

 

  const { data: formNamesData, loading: formNamesLoading } = useQuery(FORM_NAME_QUERY, {
  });
useEffect(() => {
  if (data) {
    setOpportunity(data.opportunity);
  }
}, [data]);
const id= params?.get('id');




  if (opportunityLoading || formNamesLoading) return <Loaderr />;

  return (
    <div className="main fmc-main p-0">
      <section className="container-lg container-fluid pt-5">
        <b className="text-secondary pointer" onClick={() => navigate(`/forms/entries/${id}`)}>
          <i className="iconify me-2" data-icon="mdi:arrow-left" />
          Back to Form Entries
        </b>

        <div className="p-4 bg-white border rounded-3 mb-3 mt-3 d-flex justify-content-between align-items-center">
          <h5>
            <i className="iconify me-2" data-icon="tdesign:form" />
            Opportunity Details
          </h5>
          <hr className="hr op-10" />
        </div>

        <section className="d-flex flex-column py-4 gap-3">
          <>
            <header className="header bg-white border-bottom">
              <div
                className="pointer d-flex align-items-center ms-2"
                onClick={() => navigate("/forms/")}
              >
                <img className="img-fluid" src={logo} width="30" alt="" />
                <i className="bx bx-chevron-left nav_icon mx-md-2 mx-1"></i>
                <span className="text-decoration-underline">Form Dashboard</span>
              </div>
            </header>

            <div className="p-4 bg-white border rounded-3">
              {opportunity?.formid_entryids && (
                <div>
                  <strong className="text-black-50 fw-bold mt-5">Form Entries:</strong>
                  <hr className="hr op-10" />
                  {opportunity.formid_entryids.map((entry, index) => {
                    const [formId, entryId] = entry.split("_");
                    const url = `/forms/${formId}/entries/${entryId}`;
                    const formName = formNamesData?.formsNames?.find(form => form.form_id === formId);
                    // console.log(formId,formNamesData?.formsNames.form_id); 


                    return (
                      <div key={index} className="d-flex align-items-center mb-4">
                        <span className="text-black-50 fw-bold me-2">{`Entry ${index + 1}:`}</span>
                        {formNamesLoading ? (
                        <div class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                        ) : (
                          <button className="btn btn-link p-0" onClick={() => navigate(url)}>
                            {formName?.form_name || "Form name not found"}
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}

              {opportunity &&
                Object.entries(opportunity).map(([key, value], index) => {
                  if (key === 'opp_custom_fields') return null;
                  return (
                    <div key={index}>
                      <strong className="text-black-50 fw-bold mt-5">{key.replace(/_/g, ' ')}:</strong>
                      <hr className="hr op-10" />
                      <input className="col-5 form-control p-1 mb-5" value={value || 'N/A'} disabled />
                    </div>
                  );
                })}
            </div>
          </>
        </section>
      </section>
    </div>
  );
};
