import { useContext, useEffect, useRef, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Element from "./BoardElement";

import { boardConetxt } from "../pages/form-builder/dashboard/ContextHelper";
import GetStyleClasses from "./form-builder-components/BoardElementsUI/BoardElementsSettingsUi/GetStyleClasses";
import { useParams } from "react-router-dom";
import { FormPlanContext } from "../pages/form-builder/dashboard/ContextHelper";
import { Spinner } from "../assets/images/three-dots-loading";
import { gql, useMutation } from "@apollo/client";

const UpdateFormSettingsAPI = gql`
  mutation UpdateFormSettings(
    $teamId: String!
    $formId: String
    $formFields: GraphQLJSON
    $fieldSettingsData: GraphQLJSON
  ) {
    UpdateFormSettings(
      team_id: $teamId
      form_id: $formId
      form_fields: $formFields
      field_settings_data: $fieldSettingsData
    ) {
      team_id
      form_id
    }
  }
`;

function DropBoard({
  getFormData,
  setDeleteField,
  IsaList,
  osaList,
  AdminList,
  TeamFields,
  formData,
  loading: formDataLoading,
}) {
  const ref = useRef();
  const params = useParams();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, []);

  const [UpdateFormSettings] = useMutation(UpdateFormSettingsAPI);

  const { formPlan } = useContext(FormPlanContext);
  const { board, setboard } = useContext(boardConetxt);
  const [isDragDisabled, setIsDragDisabled] = useState(false);

  useEffect(() => {
    if (formData) {
      const uniqueItems = Array.from(
        formData?.formData?.form_fields
          .reduce((map, item) => {
            if (item?.elementid && !map?.has(item?.elementid)) {
              map?.set(item?.elementid, item);
            }
            return map;
          }, new Map())
          .values()
      );

      setboard(uniqueItems); // Update the board with unique items
    }
  }, [formData]);

  const prevBoardRef = useRef([]); // Initialize with an empty array

  useEffect(() => {
    const currentBoard = board || [];

    const hasBoardChanged = () => {
      if (!prevBoardRef.current) return true; // If there's no previous board, consider it changed
      if (prevBoardRef.current.length !== currentBoard.length) return true; // Check length
      return !prevBoardRef.current.every(
        (item, index) =>
          JSON.stringify(item) === JSON.stringify(currentBoard[index])
      );
    };

    // If the board has not changed, skip the update
    if (!hasBoardChanged()) {
      return;
    }

    // Update the reference to the current board
    prevBoardRef.current = currentBoard;

    // Generate unique items by elementid
    const uniqueItems = Array.from(
      currentBoard
        .reduce((map, item) => {
          if (item?.elementid) map.set(item?.elementid, item);
          return map;
        }, new Map())
        .values()
    );

    UpdateFormSettings({
      skip: !getFormData, // Explicitly indicate that the mutation should proceed
      variables: {
        teamId: String(getFormData?.team_id),
        formId: params?.id,
        formFields: uniqueItems?.map((item) => item?.elementid),
        fieldSettingsData: uniqueItems,
      },
    });
  }, [board, params.id]);

  useEffect(() => {
    if (board?.length >= formPlan?.FieldLimit) {
      setIsDragDisabled(true);
    } else {
      setIsDragDisabled(false);
    }
  }, [board, formPlan?.FieldLimit]);

  let SaveStatus;
  if (formDataLoading) {
    SaveStatus = (
      <div className="react-loader" style={{ fontSize: "16px", color: "blue" }}>
        {Spinner}
      </div>
    );
  } else {
    SaveStatus = (
      <span
        className="d-flex align-items-center"
        style={{ fontSize: "11px", color: "green" }}
      >
        <i className="bx bx-check-double fs-4"></i>{" "}
        <span className="d-none d-lg-block">Form Saved</span>
      </span>
    );
  }
  useEffect(() => {
    const oppkeyId = "InputField-opp-key";
    const leadId = "InputField-Follow-up-boss-lead-id";
    const dispoId = "InputField-Appt-form-entry-id";
    if (board && board.length > 0) {
      const oppkeyIndex = board?.findIndex((e) => e?.elementid === oppkeyId);
      const leadIndex = board?.findIndex((e) => e?.elementid === leadId);
      const dispoIndex = board?.findIndex((e) => e?.elementid === dispoId);
      if (oppkeyIndex !== -1 && leadIndex !== -1 && dispoIndex !== -1) {
        const filteredBoard = board.filter(
          (e) =>
            e?.elementid !== oppkeyId &&
            e?.elementid !== leadId &&
            e?.elementid !== dispoId
        );
        const updatedBoard = [
          ...filteredBoard,
          board[oppkeyIndex],
          board[leadIndex],
          board[dispoIndex],
        ];
        if (JSON.stringify(board) !== JSON.stringify(updatedBoard)) {
          let uniqueData = Array.from(
            updatedBoard
              ?.reduce((map, item) => map?.set(item.elementid, item), new Map())
              .values()
          );

          setboard(uniqueData);
        }
      }
    }
  }, [board]);

  return (
    <>
      <div className="container col-xl-10 mx-auto bg-white p-4 p-xl-5 mb-5 rounded-3 border ">
        <div className="w-100 d-flex flex-wrap justify-content-end m-0 mb-3">
          <p className="w-100">
            {isDragDisabled ? (
              <div className="limit-alert w-100 my-3">
                <div className="limit-icon">
                  <i className="iconify" data-icon="dashicons:lock"></i>
                </div>
                You are Currently on {formPlan.planName} Plan , You Reached the
                Field Limit Please Upgrade Plan
              </div>
            ) : null}
          </p>
          <span>{SaveStatus}</span>
        </div>
        <div ref={ref}>
          <Droppable droppableId="dropboard">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ minHeight: "400px" }}
              >
                <div className="container px-0">
                  <div className="row align-items-end">
                    {board?.length > 0
                      ? board.map((element, index) => {
                          const type = TeamFields?.find(
                            (item) => item.field_id === element?.elementid
                          )?.field_type;
                          return (
                            <Draggable
                              key={String(element?.elementid)}
                              draggableId={String(element?.elementid)}
                              index={index}
                            >
                              {/* className="col-md-6"  */}
                              {(provided) => (
                                <div
                                  className={
                                    GetStyleClasses(element.elementid) +
                                    "m-0 mb-2"
                                  }
                                  // className="fm-comp"

                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Element
                                    element={element}
                                    setDeleteField={setDeleteField}
                                    IsaList={IsaList}
                                    osaList={osaList}
                                    AdminList={AdminList}
                                    Elementtype={type}
                                  />
                                  {/* {provided.placeholder} */}
                                </div>
                              )}
                            </Draggable>
                          );
                        })
                      : null}
                  </div>
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </>
  );
}

export default DropBoard;
