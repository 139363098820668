import React, { useContext } from "react";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";

export const HeadingUi = ({
  elementid,
  showHide,
  previewFlag = false,
}) => {
  const { board } = useContext(boardConetxt);
  const ElementSetting = board.find(
    (item) => item?.elementid === elementid
  ).settings;
  const Elemlabel = board.find(
    (item) => item?.elementid === elementid
  ).name;
  const { label, hidden } = ElementSetting;
  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };

  return (
    <>
      <div
  className={`${(previewFlag && GetStyleClasses(elementid)) || ""} ${
    hidden && previewFlag ? "d-none" : ""
  }`}        id={elementid}
        style={initStyle}
        hidden={hidden && previewFlag}
      >

        {label == undefined || label == "" ? <h2>{Elemlabel}</h2> : <h2>{label}</h2>}

      </div>
    </>
  );
};
