import { ElementsUIGenrate } from "./ElementsUIGenrate";

export default function Element({
  element,
  setDeleteField,
  IsaList,
  osaList,
  AdminList,
  Elementtype,
}) {
  const disableElementList = [
    "InputField-Follow-up-boss-lead-id",
    "InputField-opp-key",
    "InputField-Appt-form-entry-id",
  ];

  let UI;
  if (disableElementList.includes(element?.elementid)) {
    return null;
  } else {
    const Store = ElementsUIGenrate(element?.type);
    UI = Store?.ui;
  }

  return (
    <UI
      elementid={element.elementid}
      element={element}
      setDeleteField={setDeleteField}
      IsaList={IsaList}
      osaList={osaList}
      AdminList={AdminList}
      Elementtype={Elementtype}
    />
  );
}
