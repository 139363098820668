import React, { useContext } from "react";
import { ElementsSettingsWidget } from "./ElementsSettingsWidget";
import { FreeTextUi } from "./FreeTextUi";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { BoardDeleteHandler } from "../../BoardHandlerFunctions";

export const DndFreeTextUi = ({
  elementid,
  element,
  oppFields,
  setDeleteField,
  Elementtype,
}) => {
  const { board, setboard } = useContext(boardConetxt);
  const ElementSetting = board?.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.settings;
  const { position } = ElementSetting;
  let styles;
  if (position === "left" || position === "right") {
    styles = { float: `${position}` };
  } else if (position === "center") {
    styles = { textAlign: "center" };
  }

  return (
    <div className={`fm-comp ${Elementtype}`} id={elementid} style={styles}>
      <ElementsSettingsWidget
        setDeleteField={setDeleteField}
        elementid={elementid}
        board={board}
        BoardDeleteHandler={BoardDeleteHandler}
        setboard={setboard}
        element={element}
      />
      <FreeTextUi elementid={elementid} board={board} oppFields={oppFields} />
    </div>
  );
};
