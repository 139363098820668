import React, { useContext } from "react";
import { ElementsSettingsWidget } from "./ElementsSettingsWidget";
import { CheckBoxUi } from "./CheckBoxUi";
import { BoardDeleteHandler } from "../../BoardHandlerFunctions";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";

export const DndCheckBoxUi = ({
  elementid,
  element,
  oppFields,
  setDeleteField,
  Elementtype,
}) => {
  const { board, setboard } = useContext(boardConetxt);
  return (
    <div className={`fm-comp ${Elementtype}`}>
      <ElementsSettingsWidget
        setDeleteField={setDeleteField}
        BoardDeleteHandler={BoardDeleteHandler}
        elementid={elementid}
        board={board}
        setboard={setboard}
        element={element}
      />
      <CheckBoxUi elementid={elementid} oppFields={oppFields} />
    </div>
  );
};
