import ElementsSettingsModal from "../components/form-builder-components/BoardElementsUI/ElementsSettingsModal";
export const BoardAddHandler = (elementID, setboard) => {
  const item = { elementid: elementID };
  setboard((board) => [...board, item]);
};

export const BoardDeleteHandler = (e, elementid, board, setboard) => {
  const newboard = board.filter((item) => item.elementid !== elementid);

  return setboard(newboard);
};

export const BoardSettingsHandler = (e, id) => {
  e.preventDefault();
  return <ElementsSettingsModal elementid={id} type={id} />;
};
