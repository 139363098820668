import { ConditionRuleUi } from "./ConditionRuleUi";
import React, { useContext, useState, useEffect } from "react";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import cloneDeep from "lodash/cloneDeep";

function AddConditions({
  setIntialCondition,
  IntialCondition,
  condition,
  ConditionIntialState,
  setCondition,
  ConditionIndexValue,
  formdata,
  setConditionIndexValue,
}) {
  //console.log("AddConditions", ConditionIndexValue, "IntialCondition", ConditionIntialState);
  const { board, setboard } = useContext(boardConetxt);
  const [hiddneValueCheck, sethiddenValueCheck] = useState(null);
  const [hiddenValue, sethiddenValue] = useState(null);
  const [filterFields, setfilterFields] = useState([]);

  let initial = {
    condtions: [
      [
        {
          data_type: board?.[0]?.type ? board[0].type.toLowerCase() : "",
          expected_value: "",
          operator: "equals",
          field_id: board?.[0]?.elementid ? board[0].elementid : "",
        },
      ],
    ],
    Visibility: "show",
    Fields: [],
    ConditionName: "Condition Name",
  };

  const [selectedElementId, setSelectedElementId] = useState("");
  // const [IntialCondition, setIntialCondition] = useState(ConditionIntialState || initial);

  const saveCondition = () => {
    //console.log("NewCondition>>" ,ConditionIndexValue)

    if (ConditionIndexValue !== null) {
      //console.log("Which If",ConditionIndexValue)
      let newCondition = cloneDeep(condition);
      newCondition[ConditionIndexValue] = IntialCondition;
      setCondition(newCondition);
    } else {
      let newCondition = [...condition];
      //console.log("Which Else")
      //console.log("NewCondition" ,newCondition,typeof newCondition)
      newCondition?.push(IntialCondition);
      setCondition(() => newCondition);
      ConditionIndexValue = newCondition.length - 1;
      setConditionIndexValue(ConditionIndexValue);
      //console.log("NewCondition>>" ,ConditionIndexValue)
    }
  };

  //  setCondition(()=>{
  //   return IntialCondition
  //  })

  const removeCondition = (index) => {
    const values = [...IntialCondition];
    values.splice(index, 1);
    setIntialCondition(values);
  };

  const handleConditionValueChange = (
    e,
    index,
    conditionIndex,
    valueif = false
  ) => {
    const { name, value } = e.target;
    //console.log("name", name, "value>>", value);
    sethiddenValueCheck(value);
    const values = cloneDeep(IntialCondition);
    values.condtions[conditionIndex][index][name] = valueif ? value : value;
    //console.log("values", values);
    setIntialCondition(() => values);
    saveCondition();
  };
  const addAndConditionRule = (index) => {
    setIntialCondition((prev) => {
      let newCondition = cloneDeep(prev);
      newCondition.condtions[index].push({
        data_type: board?.[0]?.type ? board[0].type.toLowerCase() : "",
        expected_value: "",
        operator: "equals",
        field_id: board?.[0]?.elementid ? board[0].elementid : "",
      });
      return newCondition;
    });

    saveCondition();
  };

  const addOrRule = (index) => {
    setIntialCondition((prev) => {
      let newCondition = cloneDeep(prev);
      newCondition.condtions.push([
        {
          data_type: board?.[0]?.type ? board[0].type.toLowerCase() : "",
          expected_value: "",
          operator: "equals",
          field_id: board?.[0]?.elementid ? board[0].elementid : "",
        },
      ]);

      return newCondition;
    });

    saveCondition();
  };

  const removeConditionSet = (conditionIndex, index) => {
    if (
      IntialCondition?.condtions[0].length !== 1 ||
      IntialCondition?.condtions.length !== 1
    ) {
      let values = cloneDeep(IntialCondition);
      values.condtions[conditionIndex].splice(index, 1);
      if (values.condtions[conditionIndex].length === 0) {
        values.condtions.splice(conditionIndex, 1);
      }
      setIntialCondition(() => values);

      saveCondition();
    }
  };

  const addField = () => {
    setIntialCondition((prev) => {
      let newCondition = cloneDeep(prev);
      newCondition.Fields.push(board?.[0]?.elementid ? board[0].elementid : "");
      return newCondition;
    });
    saveCondition();
  };

  const handleFieldChange = (e, index) => {
    const { value } = e.target;
    //console.log("value", value);
    sethiddenValue(value);
    setIntialCondition((prev) => {
      let newCondition = cloneDeep(prev);
      newCondition.Fields[index] = value;
      return newCondition;
    });
    saveCondition();
  };

  const removeField = (index) => {
    setIntialCondition((prev) => {
      let newCondition = cloneDeep(prev);
      newCondition.Fields.splice(index, 1);
      return newCondition;
    });
    saveCondition();
  };

  const handelVisibilityChange = (e) => {
    setIntialCondition((prev) => {
      let newCondition = cloneDeep(prev);
      newCondition.Visibility = e.target.value;
      return newCondition;
    });
    saveCondition();
  };

  const handleDataTypeChange = (e, conditionIndex, index) => {
    //console.log(conditionIndex, index);
    var selectedIndex = e.target.selectedIndex;
    var optionElement = e.target.childNodes[selectedIndex];
    var option = optionElement.getAttribute("data-fieldtype");
    //console.log("option", option);
    setIntialCondition((prev) => {
      let newCondition = cloneDeep(prev);
      newCondition.condtions[conditionIndex][index].data_type =
        option.toLowerCase();
      newCondition.condtions[conditionIndex][index].expected_value = "";
      return newCondition;
    });

    setSelectedElementId(() => e.target.value);
    //console.log("*****selectedElementId****", e.target.value);
    saveCondition();
  };

  useEffect(() => {
    IntialCondition !== undefined
      ? saveCondition()
      : console.log("IntialCondition is undefined");
  }, [IntialCondition]);

  //console.log("rerendered", IntialCondition?.condtions,IntialCondition, "MainCondition", condition, );
  // const filterFields = formdata?.filter((field)=>{
  //   return field?.elementid != "InputField-Follow-up-boss-lead-id" && field?.elementid != "InputField-opp-key"
  // })
  //console.log("ConditionRuleState>",hiddneValueCheck)

  useEffect(() => {
    const filterFields = formdata?.filter((field) => {
      return (
        field?.elementid !== "InputField-Follow-up-boss-lead-id" &&
        field?.elementid !== "InputField-opp-key"
      );
    });
    setfilterFields(filterFields);
  }, [formdata]);

  useEffect(() => {
    if (IntialCondition) {
      let hidden = false;
      IntialCondition?.Fields?.map((item) => {
        if (item) {
          let field = formdata?.find(
            (field) => field?.elementid === item?.replace(/[{}]/g, "")
          );
          if (field && field?.settings?.hidden) {
            hidden = true;
          }
        }
      });
      sethiddenValue(hidden);
    }
  }, []);
  //console.log("Rule",IntialCondition)

  return (
    <>
      {IntialCondition?.condtions?.map((ConditionRuleState, index) => (
        <ConditionRuleUi
          formdata={formdata}
          ConditionRuleState={ConditionRuleState}
          conditionIndex={index}
          setIntialCondition={setIntialCondition}
          IntialCondition={IntialCondition}
          hiddneValueCheck={hiddneValueCheck}
          // removeCondition={removeCondition}
          handleConditionValueChange={handleConditionValueChange}
          removeConditionSet={removeConditionSet}
          addAndConditionRule={addAndConditionRule}
          addOrRule={addOrRule}
          handleDataTypeChange={handleDataTypeChange}
          selectedElementId={selectedElementId}
          key={index}
        />
      ))}

      <input
        type="button"
        className="btn-check"
        name="and_or"
        id="or_select"
        autoComplete="off"
        onClick={() => addOrRule()}
      />
      <label className="btn btn-primary-outline" htmlFor="or_select">
        OR
      </label>

      <div>
        <div className="d-flex py-2 text">
          <div
            className="iconify fs-1 text-primary"
            data-icon="gg:arrow-long-down-r"
          />
        </div>
        <div className="bg-white dot-border border-primary border-2 d-flex flex-wrap justify-content-between align-items-center p-4 px-xl-5 py-4 mb-3 rounded-3 border">
          <div className="w-100">
            <div className="mt-0">
              <div className="d-sm-flex aligm-items-center mb-4">
                <label className="col-1 me-3 fw-bold d-flex align-items-center">
                  <span className=" mb-2 mb-md-0 d-flex align-items-center">
                    <span>THEN</span>
                  </span>
                </label>

                <select
                  className="form-select input-bn"
                  onChange={(e) => handelVisibilityChange(e)}
                  required
                >
                  {/* <option value="" disabled selected hidden>Choose</option> */}

                  <option
                    value="show"
                    selected={IntialCondition?.Visibility === "show"}
                  >
                    show
                  </option>
                  <option
                    value="hide"
                    selected={IntialCondition?.Visibility === "hide"}
                  >
                    hide
                  </option>
                </select>

                {/* <option value="show">show</option>
                  <option value="hide">hide</option>
                </select> */}
              </div>
            </div>
          </div>
          <div className="w-100">
            <div className="mt-0">
              <div className="d-sm-flex aligm-items-center mb-4">
                <label className="col-1 me-3 fw-bold d-flex align-items-center">
                  <span className=" mb-2 mb-md-0 d-flex align-items-center">
                    <span>Fields</span>
                  </span>
                </label>
                <div className="d-flex flex-column w-100">
                  {IntialCondition?.Fields?.map((fields, index) => (
                    <div
                      className="d-flex flex-row align-items-center"
                      key={`${index}+${fields}`}
                    >
                      <select
                        className="form-select input-bn flex-fill mb-1"
                        id={index}
                        defaultValue={fields}
                        onChange={(e) => {
                          handleFieldChange(e, index);
                        }}
                      >
                        {/* <option value="">Please Select a Field</option> */}
                        {/* <option value="" disabled selected hidden>Choose</option> */}
                        <option value="" disabled selected hidden>
                          Please select a Option
                        </option>

                        {formdata?.map((boardField, boardkey) => {
                          // Function to decode HTML entities
                          const decodeHtml = (html) => {
                            const textArea = document.createElement("textarea");
                            textArea.innerHTML = html;
                            return textArea.value;
                          };

                          // Sanitize the label by removing HTML tags and decoding entities
                          const sanitizedLabel = boardField?.settings?.label
                            ? decodeHtml(
                                boardField.settings.label.replace(
                                  /<\/?[^>]+(>|$)/g,
                                  ""
                                )
                              )
                            : boardField?.name || `<>Unlabelled Field<>`;

                          return (
                            <option
                              value={boardField?.elementid}
                              key={boardkey}
                              disabled={
                                boardField.elementid ===
                                  "InputField-Follow-up-boss-lead-id" ||
                                boardField.elementid === "InputField-opp-key"
                              }
                            >
                              {sanitizedLabel}
                            </option>
                          );
                        })}
                      </select>
                      {fields &&
                      formdata?.find(
                        (field) =>
                          field?.elementid === fields?.replace(/[{}]/g, "")
                      )?.settings?.hidden ? (
                        <span className="text-danger text-secondary small mt-2">
                          This field is hidden
                        </span>
                      ) : (
                        ""
                      )}

                      <div className="py-2 fm-widget">
                        <span onClick={() => removeField(index)}>
                          <i
                            className="iconify mx-1 fs-6 text-danger"
                            data-icon="bx:trash"
                            id={index}
                          />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="w-100 d-flex justify-content-end position-relative">
                <div
                  className="border shadow-sm bg-white text-primary rounded-0 rounded-end rounded-pill btn accordion-flush"
                  onClick={() => addField()}
                >
                  <i className="fs-4 iconify" data-icon="mdi:plus" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddConditions;
