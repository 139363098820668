import React from "react";
import Trigger from "../triggers/Trigger";
import SelectAutomation from "./SelectAutomation";
import Fub from "../../../actions/Fub";
import TemplateFilters from "../workflows/TemplateFilters";
import Utilities from "../../automation-builder/utilities/Utilities";
import Zapier from "../../automation-builder/zapier/Zapier";


const Action = ({
  action_index,
  action_info,
  triggerdetails,
  setTriggerDetails,
  action_id,
  workflowHelper,
  selected_action,
  setSelected_action
}) => {
  const showAction = () => {
    let action_platform = action_id === "trigger"? "trigger": action_info.platform?.toLowerCase() || action_id.toLowerCase().split("#")[0];
    var action = <></>;
    if (action_platform === "trigger") {
      action = (
        <Trigger
          action_index={action_index}
          triggerdetails={triggerdetails}
          setTriggerDetails={setTriggerDetails}
          removeAction={workflowHelper}
          selected_action={selected_action}
          setSelected_action={setSelected_action}
        />
      );
    } else if (action_platform.includes("fub")) {
      action = (
        <Fub
          action_index={action_index}
          UpdateAction={workflowHelper.update_action_info(action_id)}
          action_info={action_info}
          workflowHelper={workflowHelper}
          action_id={action_id}
          // Automations={Automations["FUB"]}
          selected_action={selected_action}
          setSelected_action={setSelected_action}
          triggerdetails={triggerdetails}
        />
      );
    } else if (action_platform.includes("utility")) {
      action = (
        <Utilities
          action_index={action_index}
          UpdateAction={workflowHelper.update_action_info(action_id)}
          action_info={action_info}
          workflowHelper={workflowHelper}
          action_id={action_id}
          selected_action={selected_action}
          setSelected_action={setSelected_action}
          triggerdetails={triggerdetails}
        />
      );
    } else if (action_platform === "filter") {
      action = (
        <TemplateFilters
          action_index={action_index}
          action_info={action_info}
          UpdateAction={workflowHelper.update_action_info(action_id)}
          data_mapper={action_info.data_mapper}
          workflowHelper={workflowHelper}
          selected_action={selected_action}
          setSelected_action={setSelected_action}
          action_id={action_id}
          triggerdetails={triggerdetails}
          setTriggerDetails={setTriggerDetails}
        />
      );
    } else if (action_platform === "zapier") {
      action = (
        <Zapier
          action_index={action_index}
          action_info={action_info}
          setSelected_action={setSelected_action}
          workflowHelper={workflowHelper}
          action_id={action_id}
          selected_action={selected_action}
          UpdateAction={workflowHelper.update_action_info(action_id)}
        />
      )
    } else if(action_platform === "unknown")  {
      action=(
        <SelectAutomation
        key={"select_automation"}
        action_id={action_id}
        action_platform={action_platform}
        setSelected_action={setSelected_action}
        
        />
      )
    }

    
    return action;
  };

  return <>{showAction()}</>;
};

export default Action;
