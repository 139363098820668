import React, { useContext, useEffect, useState, useRef } from "react";
import { boardConetxt } from "../dashboard/ContextHelper";
import logo from "../../../assets/images/FULL-LOGO-BLUE.png";
import { BoardElemForPre } from "./BoardElemForPre";
import { useLocation, useNavigate } from "react-router-dom";
import { conditionContext } from "../dashboard/ContextHelper";
import { useForm, useFormContext, useWatch } from "react-hook-form";
import { get_fields_to_show } from "../../../utils/conditions_parser";
import { useParams } from "react-router-dom";
import { Loaderr } from "../../../components/Loaderr";
import { gql, useMutation, useQuery } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const opp=gql`
query Opportunity($oppKey: String) {
  opportunity(opp_key: $oppKey) {
    opp_key
    sisu_client_updated_ts
    opp_updated_ts
    opp_stage
    opp_agreement_signed_date
    fub_deal_created_ts
    opp_type
    opp_appt_date
    fub_deal_stage_name
    opp_appt_met_date
    fub_person_id
    sisu_client_created_ts
    opp_created_ts
    fub_deal_id
    opp_appt_disposition
    teamFubDealId
    team
    fub_deal_entered_stage_ts
    sisu_client_id
    opp_assigned_osa
    opp_isa
    opp_notes
    opp_address
    opp_agreement_expiration_date
    appt_set_entry_id
    fub_appt_start_time
    fub_original_appt_start_time
    disp_text_wait_timestamp
    appt_set_lead_type
    appt_set_platform
    disp_text_original_wait_timestamp
    opp_address2
    opp_city
    opp_postal_code
    opp_last_name
    opp_state
    previous_opp_stage
    CreateEntryId
    pipeline_entry_id
    opp_forecasted_close_date
    opp_under_contract_date
    appt_form_id
    form_id_entry_id
    opp_settlement_date
    CreateFormId
    appt_outcome
    external_system_key_buyer
    otc_property_id
    external_system_key_seller
    FormId_EntryIds
    formid_entryids
    appt_form_entry_ids
    opp_price
    opp_commission_percent
    fub_appt_id
    stage_id
    pipeline_id
    opp_custom_fields
    custom_fields
    create_date
  }
}`

const GetTeamUsersAndOpportunityInfo = gql`
  query GetAdminUsers(
    $teamId: String
    $getFieldsByTeamIdTeamId2: String
    $getIsaosaUsersTeamId2: String
  ) {
    GetAdminUsers(team_id: $teamId) {
      id
      name
    }
    GetFieldsByTeamId(team_id: $getFieldsByTeamIdTeamId2) {
      field_id
      field_name
      field_type
      input_type
      settings
      field_sub_type
      opportunity_field_id
    }
    GetISAOSAUsers(team_id: $getIsaosaUsersTeamId2) {
      isa_list
      osa_list
    }
   
  }
`;

const FormData = gql`
  query FormData($formId: String) {
    formData(form_id: $formId) {
      form_id
      form_name
      team_id
      form_fields
      form_conditions
      form_type
      lead_form_redirect_setting
      lead_form_redirect_url
      redirect_page_timeout
      thank_you_page_message
      form_redirection_conditions_setting

    }
  }
`;

const CreateFormEntry = gql`
  mutation CreateFormEntry($formEntryData: GraphQLJSON, $formId: String) {
    CreateFormEntry(form_entry_data: $formEntryData, form_id: $formId) {
      form_id
      form_entry_id
      entry_created_date
      form_entry_data
    }
  }
`;

function Preview() {
  const { board, setboard } = useContext(boardConetxt);
  const { condition, setCondition } = useContext(conditionContext);
  const [OppFieldData, setOppFieldData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  const [elementVisiblity, setElementVisiblity] = useState({});
  const [fieldsToShow, setFieldsToShow] = useState([]);
  const [fieldsToHide, setFieldsToHide] = useState([]);
  const [initElementVisiblity, setInitElementVisiblity] = useState({});
  const useWatchdata = useWatch();
  const {watch} = useForm({ mode: "onBlur", reValidateMode: "onBlur" });
  const [paramsContext, setParamsContext] = useState(null);
  const [signature, setSignature] = useState(null);
  const [leadId, setLeadId] = useState(null);
  const [formid, setFormId] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(false);
  const FormValues = useRef(watch());

  const params = useParams();
 

  const previewFlag = true;
  const { handleSubmit,reset} = useFormContext();
  const Location = useLocation();
  const getURLParams = new URLSearchParams(Location.search);

  const { loading: formDataLoading, data: formData } = useQuery(FormData, {
    variables: { formId: params?.id }  });

    const { data: OpportunityData, loading: OpportunityDataLoading, refetch } = useQuery(opp, {
      variables: {
        oppKey: getURLParams?.get("InputField-opp-key"),
      },
      skip: !getURLParams?.get("InputField-opp-key"), // Skip the query if oppKey is null or empty
      fetchPolicy: "network-only", // Always fetch fresh data from the server

    });
    


    const {
      data: FetchTeamAndOpportunityData,
      loading: FetchTeamAndOpportunityDataLoading,
    } = useQuery(GetTeamUsersAndOpportunityInfo, {
      variables: {
        teamId: formData?.formData?.team_id,
        getFieldsByTeamIdTeamId2: formData?.formData?.team_id,
        getIsaosaUsersTeamId2: formData?.formData?.team_id,
      },
   
      skip: !formData?.formData?.team_id,
      fetchPolicy: "network-only", // Always fetch fresh data from the server
      onCompleted: (data) => {
        refetch();
      }
    

    });
    
  useEffect(() => {
    FormValues.current = watch();
  }, [watch()]);

  useEffect(() => {
    const signatureParam = getURLParams?.get("signature");
    const contextParam = getURLParams?.get("context");
    const leadId = getURLParams?.get("InputField-Follow-up-boss-lead-id");
    const url =
      formData?.formData?.lead_form_redirect_url == null || "" ? false : true;
    setRedirectUrl(url);
    setSignature(signatureParam);
    setParamsContext(contextParam);
    setLeadId(leadId);
    setFormId(params.id);

    setShowLoader(true);
  }, []);

  const evaluateConditions = (submittedData, dataRest) => {
    for (const entry of dataRest) {
      const { conditions, url, type, form } = entry;
  
      const isValid = conditions?.some((group) => {
        return group?.every((condition) => {
          const { field_id, operator, expected_value } = condition;
          const submittedValue = submittedData[0][field_id];
          
          if (Array.isArray(submittedValue)) {
            switch (operator) {
              case "equals":
                return submittedValue?.includes(expected_value);
              case "not_equals":
                return !submittedValue?.includes(expected_value);
              case "contains":
                return submittedValue?.includes(expected_value);
              case "not_contains":
                return !submittedValue?.includes(expected_value);
              case "starts_with":
                return submittedValue?.some((val) =>
                  String(val).startsWith(expected_value),
                );
              case "ends_with":
                return submittedValue?.some((val) =>
                  String(val).endsWith(expected_value),
                );
              default:
                return false; // Invalid operator
            }
          } else {
            // Handle non-array values
            switch (operator) {
              case "equals":
                return submittedValue === expected_value;
              case "not_equals":
                return submittedValue !== expected_value;
              case "greater_than":
                return Number(submittedValue) > Number(expected_value);
              case "less_than":
                return Number(submittedValue) < Number(expected_value);
              case "contains":
                return String(submittedValue)?.includes(expected_value);
              case "not_contains":
                return !String(submittedValue)?.includes(expected_value);
              case "starts_with":
                return String(submittedValue)?.startsWith(expected_value);
              case "ends_with":
                return String(submittedValue)?.endsWith(expected_value);
              default:
                return false;
            }
          }
        });
      });

      if (isValid) {
        return { isValid: true, redirecttype: type, urlform: form,url:url };

      }
    }
  
    return { isValid: false, redirecttype: null, urlform: null };

  };
  


  const [createFormEntry, { loading: createFormEntryLoading }] = useMutation(
    CreateFormEntry,
    {
      onError: (error) => {
        toast.error('Network error occurred, please try again later', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        setShowLoader(false);
      },
      fetchPolicy: "no-cache", // Ensure fresh data

      onCompleted: (data) => {

      
      let isValid, redirecttype, urlform, url;
      let interFaceListUrl=formData?.formData?.lead_form_redirect_url;
      let settingUrl=formData?.formData?.lead_form_redirect_setting === "another_page" 


      
      if (formData?.formData?.form_redirection_conditions_setting) {
        ({ isValid, redirecttype, urlform, url } = evaluateConditions(
          data?.CreateFormEntry?.form_entry_data,
          formData?.formData?.form_redirection_conditions_setting
        ));
      }
      // console.log("isValid",paramsContext,interFaceListUrl,settingUrl)



        if (formData?.formData?.form_type === "Lead Forms") {
          if (paramsContext) {
            if(settingUrl){
            window.open(interFaceListUrl, "_blank");
            }
            setShowLoader(false);
            
            navigate(
              `/thankyou?context=${paramsContext}&signature=${signature}`,
            );
          } else if (
            formData?.formData?.lead_form_redirect_setting === "another_page"
          ) {
            setShowLoader(false);
            window.open(interFaceListUrl, "_blank");
            navigate(`/customthankyoupage`);

        }
        else{
            setShowLoader(false);
            navigate(`/customthankyoupage?formId=${params?.id}`);
          }
       }else if (isValid) {
        if(redirecttype==="form"){
          setShowLoader(false);
          navigate(`/forms/${urlform}?context=${paramsContext}&signature=${signature}&InputField-opp-key=${getURLParams?.get("InputField-opp-key")}&InputField-Follow-up-boss-lead-id=${leadId}`);
        }else if(redirecttype==="website"){
          setShowLoader(false);
          // window.location.replace(url);
          window.open(url, "_blank");
          navigate(`/thankyou?context=${paramsContext}&signature=${signature}&InputField-opp-key=${getURLParams?.get("InputField-opp-key")}&InputField-Follow-up-boss-lead-id=${leadId}`);


        }
        }else{
              setShowLoader(false);
        navigate(`/thankyou?signature=${signature}&context=${paramsContext}`);
        }
        setFormId(null);
        reset();
      },
    },
  );


  const onSubmit = (data) => {
    const currentFormId = params.id;
    if (formData.formData.form_type === "Opportunity Forms") {
      if (formid && leadId == null) {
        toast.error("some error occurred, please refresh the page", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
    } else if (formData.formData.form_type === "Lead Forms") {
      if (formData.formData.lead_form_redirect_setting === "another_page") {
        if (redirectUrl) {
          toast.error(
            "Redirect URL is not provided , Add redirect URL on setting page.",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            },
          );
          return;
        }
      }
    }

    
    createFormEntry({
      variables: {
        formId: currentFormId,
        formEntryData: [data],
      },
    });

  };

  useEffect(() => {
    setOppFieldData(OpportunityData?.opportunity);
  }, [OpportunityData?.opportunity]);

  useEffect(() => {
    if (formDataLoading || createFormEntryLoading) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [formDataLoading, createFormEntryLoading]);
  useEffect(() => {
    if (formData) {
      const uniqueItems = Array.from(
        formData?.formData?.form_fields?.reduce((map, item) => {
          if (item?.elementid && !map?.has(item?.elementid)) {
            map.set(item?.elementid, item);
          }
          return map;
        }, new Map()).values()
      );
  
      setboard(uniqueItems); // Update the board with unique items

    setCondition(() => formData?.formData.form_conditions);
    }
  }, [formData]);

  useEffect(() => {
    let getfieldsToShow = Array.from(
      get_fields_to_show(useWatchdata, condition),
    );
    const arr = Array?.from(getfieldsToShow[0]);
    const arr2 = Array?.from(getfieldsToShow[1]);
    setFieldsToShow(arr);
    setFieldsToHide(arr2);
  }, [useWatchdata]);

  useEffect(() => {
    let elemVisiblity = {};
    let Visiblity = "";
    board?.map((element) => {
      let elemkey = "";
      Visiblity = condition?.map((item) => {
        const found = item?.Fields?.find((element) => {
          elemVisiblity[element] = item?.Visibility;
        })?.Visibility;
      });
    });
    setInitElementVisiblity(() => elemVisiblity);
    setElementVisiblity((prev) => ({ ...prev, ...elemVisiblity }));
  }, [fieldsToHide, fieldsToShow, board]);

  useEffect(() => {
    setElementVisiblity((prev) => {
      let newElementVisiblity = prev;
      if (fieldsToShow?.length === 0 && fieldsToHide?.length === 0) {
        return { ...initElementVisiblity };
      }
      for (let key in initElementVisiblity) {
        if (fieldsToShow?.includes(key)) {
          newElementVisiblity[key] = "hide";
        } else if (fieldsToHide?.includes(key)) {
          newElementVisiblity[key] = "show";
        }
      }
      return { ...newElementVisiblity };
    });
  }, [fieldsToShow, fieldsToHide]);

  if (showLoader || FetchTeamAndOpportunityDataLoading || OpportunityDataLoading) return <Loaderr />;

  return (
    <>
      {createFormEntryLoading && <Loaderr />}
      <ToastContainer />
      <div
        className="main fmc-main px-0"
        style={{ backgroundColor: "#065697", marginTop: "0px" }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className="fm-condition mt-0">
            <section className="container-fluid mb-5 pb-5 col-lg-12 mx-auto">
              <div
                className="bg-white d-flex flex-wrap justify-content-center align-items-center py-3 px-4 mb-lg-5 mb-4 fixed-top"
                style={{ marginTop: "0px" }}
              >
                <span className="m-0 d-flex align-items-center">
                  <i
                    className="iconify nav_icon me-1 fs-4"
                    data-icon="ic:baseline-dynamic-form"
                  />
                  {formData?.formData?.form_name}
                </span>
              </div>
              <section className="cols-xxl-6 col-xl-7 col-lg-8 col-md-8 col-sm-10 col-12 mb-5 pb-5 mx-auto d-flex align-items-center justify-content-center mt-5 pt-3">
                <div className="bg-white p-4 p-xl-5 mb-5 rounded-3 border w-100">
                  <div className="w-100 px-2"></div>
                  <div className="d-flex border-bottom pb-3 mb-4">
                    <img
                      className="img-fluid mx-lg-auto"
                      src={logo}
                      width={130}
                      alt=""
                    />
                  </div>
                  <div className="container">
                    <div className="row align-items-end">
                      {board?.length > 0 &&
                        board?.map((element, index) => {
                          const showHide = elementVisiblity[element?.elementid];
                          const OppFieldName =
                            FetchTeamAndOpportunityData?.GetFieldsByTeamId?.find(
                              (item) => String(item?.field_id) === String(element?.elementid),
                            )?.opportunity_field_id;

                          let fieldName = OppFieldName?.split("_")
                            .slice(1)
                            .join("_");
                          const OppFieldName2 =
                            FetchTeamAndOpportunityData?.GetFieldsByTeamId?.map(
                              (item) =>
                                String(item?.field_id) === String(element?.elementid)
                                  ? item?.opportunity_field_id
                                  : null,
                            )?.filter((id) => id !== null)[0];
                          let Oppvalue;
                          if (fieldName === "appt_form_entry_id") {
                            Oppvalue = OppFieldData?.appt_form_entry_ids?.[0];
                          } else {
                            Oppvalue =
                              OppFieldData?.[fieldName] ||
                              OppFieldData?.opp_custom_fields?.[OppFieldName2];
                          }

                          return (
                            <BoardElemForPre
                              element={element}
                              key={index}
                              OppFieldName={OppFieldName}
                              oppData={Oppvalue}
                              showHide={showHide}
                              setElementVisiblity={setElementVisiblity}
                              previewFlag={previewFlag}
                              IsaList={
                                FetchTeamAndOpportunityData?.GetISAOSAUsers
                                  ?.isa_list
                              }
                              osaList={
                                FetchTeamAndOpportunityData?.GetISAOSAUsers
                                  ?.osa_list
                              }
                              AdminList={
                                FetchTeamAndOpportunityData?.GetAdminUsers
                              }
                            />
                          );
                        })}
                    </div>
                  </div>

                  <div className="d-flex justify-content-end mt-4 w-100">
                    <div className="d-flex align-items-center">
                      <button className="btn btn-primary" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </section>
        </form>
      </div>
    </>
  );
}

export default Preview;
