import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { Loaderr } from "../../../components/Loaderr";
import logo from "../../../assets/images/LOGO-BLUE.png";
import FormEntryAgGridTable from "../dashboard/FormEntryAGridTable";

const GetFormStatsAndEntries = gql`
  query GetFormStatsAndEntries(
    $formId: String
    $countFormEntriesTodayFormId2: String
    $formDataFormId2: String
    $formEntriesFormId2: String
  ) {
    countFormEntries(form_id: $formId) {
      count
    }
    countFormEntriesToday(form_id: $countFormEntriesTodayFormId2) {
      count
    }
    formData(form_id: $formDataFormId2) {
      base_form_template
      form_description
      form_name
      form_type
      team_id

    }
    formEntries(form_id: $formEntriesFormId2) {
      form_entry_id
      form_id
      form_entry_data
      entry_created_date
    }
  }
`;

function FormEntries() {
  const navigate = useNavigate();
  const params = useParams();
  const [allEntries, setAllEntries] = useState([]);
  const [entries, setEntries] = useState([]);

  const { data: GetFormStatsAndEntrie, loading } = useQuery(
    GetFormStatsAndEntries,
    {
      variables: {
        formId: params.id,
        countFormEntriesTodayFormId2: params.id,
        formDataFormId2: params.id,
        formEntriesFormId2: params.id,
      },
    },
  );

  useEffect(() => {
    if (GetFormStatsAndEntrie?.formEntries) {
      // Reverse the entries only if data is present
      setEntries([...GetFormStatsAndEntrie?.formEntries]);
    } else {
    }
  }, [GetFormStatsAndEntrie?.formEntries]);

  useEffect(() => {
    if (!Array.isArray(entries)) return; // Ensure `entries` is an array

    const transformedEntries = entries.map((entry) => {
      const transformedObj = { ...entry };

      if (Array.isArray(entry.form_entry_data)) {
        entry.form_entry_data.forEach((data) => {
          if (data && typeof data === "object") {
            Object.entries(data).forEach(([key, value]) => {
              transformedObj[key] = value;
            });
          }
        });
      }

      delete transformedObj.form_entry_data; // Optional: Remove `form_entry_data` if not needed
      return transformedObj;
    });

    setAllEntries(transformedEntries);
  }, [entries]);

  if (loading) return <Loaderr />;

  return (
    <>
      <header className="header bg-white border-bottom" id="header">
        <div>
          <div className="pointer" onClick={() => navigate("/forms/")}>
            <div className="d-flex align-items-center ms-2">
              <span className="navbar-brand m-0 p-0">
                <img className="img-fluid" src={logo} width="30" alt="" />
              </span>
              <i className="bx bx-chevron-left nav_icon mx-md-2 mx-1"></i>
              <span className="p-0 m-0 text-decoration-underline">
                Form Dashboard
              </span>
            </div>
          </div>
        </div>
      </header>
      <div className="main fmc-main p-0">
        <section className="container-lg container-fluid pt-5">
          <div className="p-4 bg-white border rounded-3 mb-3 mt-3">
            <h5>
              <i className="iconify me-2" data-icon="tdesign:form" />
              {GetFormStatsAndEntrie?.formData?.form_name}
            </h5>
            <p className="text-secondary mt-2 m-0">
              <span className="text-dark me-2">Description:</span>
              {GetFormStatsAndEntrie?.formData?.form_description}
            </p>
            <p className="text-secondary mt-2 m-0">
              <span className="text-dark me-2">Form Type:</span>
              {GetFormStatsAndEntrie?.formData?.form_type}
            </p>
            <p className="text-secondary mt-2 m-0">
              <span className="text-dark me-2">Form template:</span>
              {GetFormStatsAndEntrie?.formData?.base_form_template ===
              ""
                ? "Custom Form"
                : GetFormStatsAndEntrie?.formData?.base_form_template}
            </p>
            <hr className="hr op-10" />
            <div className="d-flex mt-3">
              <div className="pe-5 border-end text-center">
                <span>Total entries</span>
                <h3 className="mt-2">
                  {GetFormStatsAndEntrie?.countFormEntries.count}
                </h3>
              </div>
              <div className="px-5 border-end text-center">
                <span>Today</span>
                <h3 className="mt-2">
                  {GetFormStatsAndEntrie?.countFormEntriesToday?.count}
                </h3>
              </div>
            </div>
          </div>
          {allEntries.length === 0 ? (
  <div className="alert alert-info text-center mt-3" role="alert">
    <strong>No entries available!</strong> Please add some entries to view them here.
  </div>
) : (
  <FormEntryAgGridTable
    entries={allEntries}
    teamid={GetFormStatsAndEntrie?.formData?.team_id}
  />
)}


        </section>
      </div>
    </>
  );
}

export default FormEntries;
