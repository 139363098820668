import React, { useContext, useEffect } from "react";
import { DatePicker } from "antd";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { Controller, useFormContext } from "react-hook-form";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

export const DateUi = ({
  elementid,
  showHide,
  oppFields,
  previewFlag = false,
}) => {
  const { board } = useContext(boardConetxt);
  const disableElementForBuild = window.location.pathname?.includes("build");

  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  const Location = useLocation();

  useEffect(() => {
    if (oppFields) {
      setValue(elementid, oppFields);
    }
  }, [oppFields, elementid, setValue]);

  useEffect(() => {
    const params = new URLSearchParams(Location.search);
    const updatedParamValue = params.get(elementid);

    if (elementid && updatedParamValue) {
      setValue(elementid, updatedParamValue);
    }
  }, [Location.search, elementid, setValue]);

  const ElementSettingsData = board?.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.settings;

  const ElementName = board?.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.name;

  const { label, instructions, required, hidden } = ElementSettingsData || {};
  const initialStyle = {
    display: showHide === "show" ? "none" : "block",
  };

  const checkingValidation = () => {
    if (Boolean(required) === true && String(showHide) === "show") {
      return false;
    } else if (Boolean(required) === false && String(showHide) === "show") {
      return false;
    } else if (Boolean(required) === true && String(showHide) === "hide") {
      return false;
    } else if (Boolean(required) === false && String(showHide) === "hide") {
      return false;
    } else if (Boolean(required) === true && hidden === true) {
      return false;
    } else if (Boolean(required) === true) {
      return true;
    } else {
      return false;
    }
  };

  const normalizeFieldName = (name) => name?.replace(/[.\s]/g, "_");

  return (
    <div
      className={`${(previewFlag && GetStyleClasses(elementid)) || ""} ${
        hidden && previewFlag ? "d-none" : ""
      }`}
      style={initialStyle}
      id={elementid}
      hidden={hidden && previewFlag}
    >
      <label>
        <span className="d-flex mb-2 align-items-center">
          <i className="iconify me-1 mr-1 fs-5" data-icon="f7:calendar" />
          <span>
            {label || ElementName}
            {required && <span style={{ color: "red" }}>*</span>}
          </span>
        </span>
      </label>
      <div className="form-floating form-group">
        <Controller
          control={control}
          name={normalizeFieldName(elementid)}
          rules={{
            required: {
              value: checkingValidation(),
              message: "This field is required",
            },
          }}
          render={({ field }) => (
            <DatePicker
              format="MM/DD/YYYY"
              disabled={disableElementForBuild}
              className="date-picker-input form-control input-bn w-100"
              value={field.value ? dayjs(field.value) : null} // Controlled value
              onChange={(date, dateString) => {
                field.onChange(dateString); // Update React Hook Form
                setValue(elementid, dateString); // Ensure synchronization
              }}
              id={normalizeFieldName(elementid)}
            />
          )}
        />
      </div>

      <small className="text-secondary">{instructions}</small>
      {errors[elementid] && (
        <span className="text-danger">{errors[elementid]?.message}</span>
      )}
    </div>
  );
};
