import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/LOGO-BLUE.png";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useForm } from "react-hook-form";

dayjs.extend(customParseFormat);

export function FormEntryDataTable({
  formData,
  formEntryData,
  handleInputChange,
}) {
  const { register, handleSubmit, formState: { errors }, control, trigger } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const navigate = useNavigate();
  const [check, setCheck] = useState([true, false]);



  const handleOpp = (value) => {
    navigate(`/forms/opportunities/${value}`);
  };

  const handleCheckboxChange = (elementId, value) => {
    const updatedCheckboxValues = [...formEntryData.formEntry.form_entry_data[0][elementId]];
    const index = updatedCheckboxValues.indexOf(value);
    if (index === -1) {
      updatedCheckboxValues.push(value);
    } else {
      updatedCheckboxValues.splice(index, 1);
    }
    handleInputChange({
      target: { id: elementId, value: updatedCheckboxValues }
    });
  };

  const handleInputType = (entry) => {
    const { elementid, type, settings } = entry;
    const value = formEntryData?.formEntry?.form_entry_data[0][elementid] || "";

    const commonProps = {
      className: "col-5 form-control",
      id: elementid,
      value,
      disabled: "true",
      ...register(elementid, {
        required: settings?.required ? {
          value: !value,
          message: "This field is required",
        } : false,
        maxLength: settings?.maxLength || 80,
        onChange: handleInputChange
      })
    };

    switch (type) {
      case "Email":
        return <input {...commonProps} type="email" pattern="^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$" />;
      case "NumberField":
        return <input {...commonProps} type="number" pattern="^[0-9]*$" />;
      case "Date":
        return (
          <DatePicker
            {...commonProps}
            format="MM/DD/YYYY"
            onChange={(date, dateString) => handleInputChange({ target: { name: elementid, value: dateString } })}
            value={dayjs(value)}
          />
        );
      case "RadioButton":
      case "DropDown":
        return (
          <select {...commonProps}>
            <option value="">Please Select</option>
            {settings?.options?.map(item => (
              <option key={item.id} value={item.value}>{item.value}</option>
            ))}
          </select>
        );
      case "CheckBox":
        return settings?.options?.map(item => (
          <div key={item.id} className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id={item.id}
              value={item.value}
              checked={value.includes(item.value)}
              onChange={() => handleCheckboxChange(elementid, item.value)}
              disabled="true"
            />
            <label className="form-check-label text-secondary ms-1" htmlFor={item.value}>
              {item.value}
            </label>
          </div>
        ));
      case "InputField":
        return entry.name.toLowerCase() === "opp key" ? (
          <button
            className="text-decoration-underline pointer btn-hover-blue"
            onClick={() => handleOpp(value)}
          >
            View Opportunity
          </button>
        ) : (
          <input {...commonProps} />
        );
      case "TextArea":
        return <textarea {...commonProps} />;
      case "Contact":
        return <input {...commonProps} pattern="^[0-9]*$" />;
      case "Time":
        return (
          <TimePicker
            {...commonProps}
            format="HH:mm a"
            minuteStep={5}
            onChange={(time, timeString) => handleInputChange({ target: { name: elementid, value: timeString } })}
            value={dayjs(value, "HH:mm a")}
          />
        );
      case "Ratings":
        return (
          <select {...commonProps}>
            <option value="">Please Select</option>
            {[...Array(5)].map((_, index) => (
              <option key={index} value={index + 1}>{index + 1}</option>
            ))}
          </select>
        );
      default:
        return null;
    }
  };

  const renderFormFields = (filterCondition) => {
    return formData?.form_fields
      ?.filter(entry => filterCondition(entry))
      .map((entry, index) => (
        <div className="fs-6" key={index}>
          <div className="text-black-50 fw-bold">
            {entry.type === "Headings" ? entry?.settings?.title :
              entry.type === "FreeText" ? entry?.settings?.description :
                entry.settings.label || entry.name
            }
            {entry.settings?.required && <span className="text-danger ms-1">*</span>}
          </div>
          {(entry.type !== "Headings" && entry.type !== "FreeText") && (
            <>
              <hr className="hr op-10" />
              {handleInputType(entry)}
            </>
          )}
        </div>
      ));
  };

  return (
    <>
      <header className="header bg-white border-bottom" id="header">
        <div>
          <div className="pointer" onClick={() => navigate("/forms/")}>
            <div className="d-flex align-items-center ms-2">
              <span className="navbar-brand m-0 p-0">
                <img className="img-fluid" src={logo} width="30" alt="" />
              </span>
              <i className="bx bx-chevron-left nav_icon mx-md-2 mx-1"></i>
              <span className="p-0 m-0 text-decoration-underline">Form Dashboard</span>
            </div>
          </div>
        </div>
      </header>
      <div className="p-4 bg-white border rounded-3 mb-3 mt-3">
        <div className="d-flex flex-column gap-5 mb-3 mt-3">
          {renderFormFields(entry => entry?.name?.toLowerCase() === "opp key")}
          {renderFormFields(entry => entry?.name?.toLowerCase() !== "opp key")}
        </div>
      </div>
    </>
  );
}
