import { useContext, useEffect } from "react";

import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { Controller, useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";

export const InputUi = ({
  elementid,
  showHide,
  previewFlag = false,
  oppFields,
}) => {
  const { board } = useContext(boardConetxt);
  const ElementSetting = board?.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.settings;
  const disableElementForBuild = window.location.pathname.includes("build");

  const {
    label,
    inputType,
    instructions,
    minValue,
    maxValue,
    predefine,
    required,
    hidden: initialHidden,
  } = ElementSetting;
  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };
  //console.log("showHide", showHide)
  const {
    formState: { errors },
    setValue,
    control,
  } = useFormContext();
  useEffect(() => {
    if (predefine !== undefined) {
      setValue(elementid, predefine);
    }
  }, [predefine]);

  useEffect(() => {
    if (oppFields) {
      setValue(elementid, oppFields);
    }
  }, [oppFields, elementid, setValue]);

 const Location = useLocation();

    useEffect(() => {
      const params = new URLSearchParams(Location.search);
      const updatedParamValue = params.get(elementid);
    
      if (elementid && updatedParamValue) {
        setValue(elementid, updatedParamValue);
      }
    }, [elementid, setValue]);

  const ElementName = board?.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.name;
  const checkingValidation = () => {
    if (Boolean(required) === true && String(showHide) === "show") {
      return false;
    } else if (Boolean(required) === false && String(showHide) === "show") {
      return false;
    } else if (Boolean(required) === true && String(showHide) === "hide") {
      return false;
    } else if (Boolean(required) === false && String(showHide) === "hide") {
      return false;
    } else if (Boolean(required) === true && hidden === true) {
      return false;
    } else if (Boolean(required) === true) {
      return true;
    } else {
      return false;
    }
  };
  const normalizeFieldName = (name) => {
    return name?.replace(/[.\s]/g, "_"); // Replace dots and spaces with underscores
  };
  const isOppKey = String(elementid) === "InputField-opp-key";
  const isFollowUpBossLeadId =
    String(elementid) === "InputField-Follow-up-boss-lead-id";
  const isApptFormEntryId =
    String(elementid) === "InputField-Appt-form-entry-id";
  const hidden =
    isOppKey || isFollowUpBossLeadId || isApptFormEntryId
      ? true
      : initialHidden; // Set hidden to true for either key

  return (
    <div
    className={`${(previewFlag && GetStyleClasses(elementid)) || ""} ${
      hidden && previewFlag ? "d-none" : ""
    }`}      id={elementid}
      style={initStyle}
      hidden={hidden && previewFlag}
    >
      <label>
        <span className="d-flex mb-2 align-items-center">
          <span>
            {label === undefined || label === "" ? ElementName : label}

            {required ? <span style={{ color: "red" }}>*</span> : ""}
          </span>
        </span>
      </label>

      <div className="w-100 form-group">
        {Object.keys(ElementSetting)?.length === 0 ? (
          <Controller
            control={control}
            name={normalizeFieldName(elementid)}
            rules={{
              maxLength: {
                value: 100,
                message: `Please Enter Maximum 60 Characters`,
              },
            }}
            render={({ field }) => (
              <input
                type="text"
                className="form-control input-bn"
                name="input"
                defaultValue={""}
                placeholder="Enter Text"
                disabled={disableElementForBuild}
                {...field}
              />
            )}
          />
        ) : (
          <Controller
            control={control}
            name={normalizeFieldName(elementid)}
            rules={{
              required: {
                value: checkingValidation(),
                // !hidden && showHide == "hide" && required === true ? true : false
                message: "This Field is Required",
              },
              minLength: {
                value: String(minValue) === "" ? 0 : minValue,
                message: `Please Enter More then ${
                  String(minValue) === "" ? 0 : minValue
                } Characters`,
              },
              maxLength: {
                value: String(maxValue) === "" ? 80 : maxValue,
                message: `Please Enter Maximum ${
                  String(maxValue) === "" ? 80 : maxValue
                } Characters`,
              },
            }}
            render={({ field }) => (
              <input
                type={inputType ? inputType : "text"}
                className="form-control input-bn"
                name="input"
                defaultValue={predefine === undefined || "" ? "" : predefine}
                placeholder="Enter Text"
                disabled={disableElementForBuild}
                {...field}
              />
            )}
          />
        )}
      </div>
      <small className="text-secondary">{instructions}</small>
      <div className="d-flex flex-column gap-2">
        {errors && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>
    </div>
  );
};
