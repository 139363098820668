import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { boardConetxt, conditionContext } from "../dashboard/ContextHelper";
import ConditionCreateEdit from "./ConditionCreateEdit";
import useFormApiHelper from "../../../utils/useFormApiHelper";
import { Loaderr } from "../../../components/Loaderr";
import ConditionEmpty from "./ConditionsEmpty";

const UpdateFormConditionsAPI = gql`
  mutation UpdateFormSettings(
    $teamId: String!
    $formId: String
    $formConditions: GraphQLJSON
  ) {
    UpdateFormSettings(
      team_id: $teamId
      form_id: $formId
      form_conditions: $formConditions
    ) {
      team_id
      form_id
    }
  }
`;

export default function ConditionDashboard() {
  const params = useParams();
  const { GetFormData } = useFormApiHelper();

  const { formDataLoading, formData } = GetFormData(params);
  const [ConditionIndexValue, setConditionIndexValue] = React.useState(null);

  const { condition, setCondition } = useContext(conditionContext);

  const { board } = useContext(boardConetxt);
  const [ConditionIntialState, setConditionIntialState] = useState(null);
  const [ShowDashBoardComponent, setShowDashBoardComponent] = useState(true);
  const [UpdateFormSettings, { loading }] = useMutation(
    UpdateFormConditionsAPI
  );

  const handleAddNewCondition = () => {
    // //console.log("handleAddNewCondition");

    setConditionIndexValue(null);
    setShowDashBoardComponent(false);
  };

  const handelEdit = (e, index) => {
    setShowDashBoardComponent(false);
    setConditionIntialState(condition[index]);
    return setConditionIndexValue(index);
  };

  const handleConditionDelete = (e, index) => {
    const newCondition = [...condition];
    newCondition.splice(index, 1);
    setCondition(newCondition);

    UpdateFormSettings({
      variables: {
        teamId: String(formData?.formData?.team_id),
        formId: params.id,
        formConditions: newCondition,
      },
    });
  };

  useEffect(() => {
    setCondition(formData?.formData?.form_conditions || []);
  }, [formDataLoading]);

  if (formDataLoading || loading) return <Loaderr />;

  return (
    <>
      {ShowDashBoardComponent ? (
        <div className="main fmc-main">
          <section className="fm-condition">
            <div
              className="d-lg-none alert alert-warning banner-top p-3 py-2 mb-3 rounded-0 m-0"
              role="alert"
            >
              <div className="d-flex flex-wrap align-items-center">
                <div className="d-flex align-items-center my-2">
                  <i className="bx bx-mobile fs-5" />
                  <i className="bx bx-arrow-to-right me-2 fs-5" />
                  <i className="bx bx-desktop me-2 fs-5" />
                </div>
                <span className="my-2">
                  Please switch to desktop and optimize this view. Smaller
                  screens are not efficient.
                </span>
              </div>
            </div>
            <section className="col-xxl-6 col-lg-7 col-md-9 mb-5 pb-5 col-12 mx-auto">
              <div className="d-flex flex-wrap justify-content-between align-items-center mb-4 py-4 border-bottom">
                <h5 className="m-0">Conditions</h5>
                <button
                  onClick={() => {
                    handleAddNewCondition();
                  }}
                  // to={`/forms/conditions/create-edit/${params.id}`}
                  //   href="fm-conditions-create.html"
                  className="d-flex align-items-center btn btn-primary-outline"
                >
                  <i className="iconify me-md-1 nav_icon" data-icon="bx:plus" />
                  <span className="d-sm-block d-none">Add New conditions</span>
                </button>
              </div>

              {condition?.length !== 0 &&
                condition?.map((conditions, index) => {
                  //console.log("conditions>>", conditions)
                  return (
                    <div
                      className="bg-white d-flex flex-column flex-wrap rounded-3 border position-relative mb-4"
                      key={index}
                    >
                      <div className="d-flex w-100 flex-wrap rounded-top justify-content-between align-items-center border-bottom bg-color-1 p-4 py-3">
                        <span className="m-0">{`${conditions?.ConditionName} ${
                          index + 1
                        }`}</span>
                        <div className="d-flex align-items-center">
                          <span
                            title="Edit"
                            onClick={(e) => {
                              handelEdit(e, index);
                            }}
                          >
                            <i
                              className="iconify me-md-1 nav_icon pointer"
                              data-icon="mdi:pencil"
                            />
                          </span>
                          <span
                            title="Delete"
                            onClick={(e) => {
                              handleConditionDelete(e, index);
                            }}
                          >
                            <i
                              className="iconify me-md-1 nav_icon pointer text-danger ms-2"
                              data-icon="mdi:trash"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="p-4">
                        <div className="d-flex flex-wrap gap-2 align-items-center ">
                          <b className="w-100">IF</b>
                          {conditions?.condtions?.map(
                            (cond, conditions_index) => {
                              //console.log("cond>>>>", cond);
                              return cond.map((intCon, index) => {
                                //console.log("intCon>>>>", intCon);
                                return (
                                  <>
                                    {/* {index == 0 &&  <span id={index}> (</span>} */}

                                    <div className="border dot-border dt-cont p-2 px-3 rounded">
                                      {/* {intCon.field_id} */}
                                      {formData?.formData?.form_fields?.find(
                                        (obj) =>
                                          intCon.field_id === obj.elementid
                                      )?.settings?.placeHolder ||
                                        formData?.formData?.form_fields?.find(
                                          (obj) =>
                                            intCon.field_id === obj.elementid
                                        )?.settings?.label ||
                                        formData?.formData?.form_fields?.find(
                                          (obj) =>
                                            intCon.field_id === obj.elementid
                                        )?.name || (
                                          <span
                                            style={{ color: "red" }}
                                            title="Field is Deleted"
                                          >
                                            ?? Field is Deleted
                                          </span>
                                        )}{" "}
                                      <b className="mx-2 text-primary">
                                        {intCon?.operator?.toUpperCase()}
                                      </b>{" "}
                                      <b>{intCon?.expected_value}</b>
                                    </div>

                                    {cond?.length - 1 > index ? (
                                      <span id={index}>
                                        {" "}
                                        <b>AND</b>
                                      </span>
                                    ) : (
                                      <>
                                        {conditions_index <
                                        conditions?.condtions?.length - 1 ? (
                                          // <span id={index}>) OR  </span>
                                          <span className="w-100" id={index}>
                                            {" "}
                                            <b>OR</b>{" "}
                                          </span>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                  </>
                                );
                              });
                            }
                          )}
                        </div>
                        <hr className="hr op-10 my-4" />
                        <div className="d-flex flex-wrap align-items-center">
                          <b>THEN</b>
                          <b className="text-primary ms-3">
                            {conditions?.Visibility?.toUpperCase()}
                          </b>

                          {conditions?.Fields?.map((action, index) => {
                            return (
                              <div className="border dot-border dt-cont p-2 mx-2 rounded" key={index}>
                                {/* {action} */}
                                {formData?.formData?.form_fields?.find(
                                  (obj) => action === obj.elementid
                                )?.settings?.placeHolder ||
                                  formData?.formData?.form_fields?.find(
                                    (obj) => action === obj.elementid
                                  )?.settings?.label ||
                                  formData?.formData?.form_fields?.find(
                                    (obj) => action === obj.elementid
                                  )?.name || (
                                    <span style={{ color: "red" }}>
                                      ?? Field is Deleted
                                    </span>
                                  )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              {condition?.length === 0 && <ConditionEmpty />}

              {/* --------------------------------------------------------------- */}
            </section>
          </section>
        </div>
      ) : (
        <ConditionCreateEdit
          board={board}
          ConditionIndexValue={ConditionIndexValue}
          setConditionIndexValue={setConditionIndexValue}
          ConditionIntialState={ConditionIntialState}
          setConditionIntialState={setConditionIntialState}
          ShowDashBoardComponent={ShowDashBoardComponent}
          formdata={formData?.formData?.form_fields}
          setShowDashBoardComponent={setShowDashBoardComponent}
        />
      )}
    </>
  );
}
