import React, { useContext, useEffect } from "react";

import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { Controller, useFormContext } from "react-hook-form";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { useLocation } from "react-router-dom";
export const TextareaUi = ({
  elementid,
  showHide,
  previewFlag = false,
  oppFields,
}) => {
  const { board } = useContext(boardConetxt);
  const disableElementForBuild = window.location.pathname.includes("build");

  const ElementSettingsData = board?.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.settings;
  const {
    label,
    inputType,
    instructions,
    minValue,
    maxValue,
    predefine,
    required,
    hidden,
  } = ElementSettingsData;
  const initialStyle = {
    display: showHide === "show" ? "none" : "block",
  };

  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  useEffect(() => {
    if (oppFields) {
      setValue(elementid, oppFields);
    }
  }, [oppFields, elementid, setValue]);

  const Location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(Location.search);
    const updatedParamValue = params.get(elementid);

    if (elementid && updatedParamValue) {
      setValue(elementid, updatedParamValue);
    }
  }, [elementid, setValue]);

  const ElementName = board?.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.name;
  const checkingValidation = () => {
    if (Boolean(required) === true && String(showHide) === "show") {
      return false;
    } else if (Boolean(required) === false && String(showHide) === "show") {
      return false;
    } else if (Boolean(required) === true && String(showHide) === "hide") {
      return false;
    } else if (Boolean(required) === false && String(showHide) === "hide") {
      return false;
    } else if (Boolean(required) === true && hidden === true) {
      return false;
    } else if (Boolean(required) === true) {
      return true;
    } else {
      return false;
    }
  };
  const normalizeFieldName = (name) => {
    return name?.replace(/[.\s]/g, "_"); // Replace dots and spaces with underscores
  };
  return (
    <div
      className={`${(previewFlag && GetStyleClasses(elementid)) || ""} ${
        hidden && previewFlag ? "d-none" : ""
      }`}
      style={initialStyle}
      hidden={hidden && previewFlag}
    >
      <label>
        <span className="d-flex mb-2 align-items-center">
          <i className="iconify me-1 mr-1 fs-5" data-icon="mdi:text"></i>

          {label === undefined || label === "" ? ElementName : label}

          {required ? <span style={{ color: "red" }}>*</span> : ""}
        </span>
      </label>
      <div className="w-100 form-group">
        {Object?.keys(ElementSettingsData)?.length === 0 ? (
          <Controller
            control={control}
            name={normalizeFieldName(elementid)}
            rules={{
              maxLength: {
                value: 500,
                message: `Please Enter Maximum 500 Characters`,
              },
            }}
            render={({ field }) => (
              <textarea
                placeholder="Enter Text Here"
                className="form-control input-bn"
                type="text"
                name="textarea"
                disabled={disableElementForBuild}
                id={normalizeFieldName(elementid)}
                {...field}
              />
            )}
          />
        ) : (
          <Controller
            control={control}
            name={normalizeFieldName(elementid)}
            rules={{
              required: {
                value: checkingValidation(),
                message: "This Field is Required",
              },
              minLength: {
                value: minValue === "" ? 0 : minValue,
                message: `Please Enter More then ${
                  minValue === "" ? 0 : minValue
                } Characters`,
              },
              maxLength: {
                value: maxValue === "" ? 500 : maxValue,
                message: `Please Enter Maximum ${
                  maxValue === "" ? 500 : maxValue
                } Characters`,
              },
            }}
            render={({ field }) => (
              <textarea
                placeholder="Enter Text Here"
                className="form-control input-bn"
                type={inputType ? inputType : "text"}
                name="textarea"
                disabled={disableElementForBuild}
                defaultValue={predefine === undefined ? "" : predefine}
                {...field}
              />
            )}
          />
        )}
      </div>
      <small className="text-secondary">{instructions}</small>

      <div className="d-flex flex-column gap-2">
        {errors && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>
    </div>
  );
};
