import React, { useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { ModalFooter } from "./ModalFooter";

const FreeTextSettings = ({
  elementid,
  handleCloseModalClick,
  elementSettingsData,
  board,
}) => {
  const ElementName = board.find((item) => item?.elementid === elementid).name;
  const initialvalue = {
    label: ElementName,
    size: "large",
    inputType: "text",
    required: false,
    minValue: "",
    maxValue: "",
    instructions: "",
  };

  const [FreeText, setFreeText] = useState(
    Object.keys(elementSettingsData).length !== 0
      ? elementSettingsData
      : initialvalue
  );

  const [editorState, setEditorState] = useState(() => {
    const html = elementSettingsData?.label || ElementName;
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  });

  const handleEditorChange = (state) => {
    setEditorState(state);
    const rawContent = draftToHtml(convertToRaw(state.getCurrentContent()));
    setFreeText({ ...FreeText, label: rawContent });
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFreeText({ ...FreeText, [name]: value });
  };

  return (
    <>
      <div className="modal-body py-4 px-lg-5 p-md-4">
        <h5 className="mb-4">
          <i
            className="iconify fs-5 pointer me-1"
            data-icon="tabler:checkbox"
          />
          Field Settings
        </h5>
        <div className="mb-4">
          <label className="form-label">
            <span className="d-flex mb-2 align-items-center">
              <span>Free Text Label</span>
            </span>
          </label>
          <div className="editor-container">
            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              toolbar={{
                options: ["inline", "link", "history"],
                inline: { options: ["bold", "italic", "underline"] },
                link: {
                  options: ["link", "unlink"],
                  showOpenOptionOnHover: true,
                  defaultTargetOption: "_blank",
                },
              }}
              placeholder="Enter your free text here..."
              className="form-control input-bn"
            />
          </div>
        </div>
        <hr className="hr my-5" />
        <h5 className="mb-4">
          <i className="iconify fs-5 pointer me-1" data-icon="bx:slider" />
          General Settings
        </h5>
        <div className="mb-4">
          <label className="form-label">
            <span>Alignment</span>
          </label>
          <select
            className="form-select input-bn"
            value={FreeText.size || ""}
            name="size"
            onChange={handleInputChange}
          >
            <option value="left">Left</option>
            <option value="right">Right</option>
            <option value="center">Center</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="form-label">
            <span>Instructions for Users (max 100 char)</span>
          </label>
          <textarea
            className="form-control input-bn"
            style={{ minHeight: "100px" }}
            name="instructions"
            value={FreeText.instructions || ""}
            onChange={handleInputChange}
            maxLength="100"
            placeholder="Add instructions here..."
          />
        </div>
      </div>
      <ModalFooter
        ElementSettingData={FreeText}
        handleCloseModalClick={handleCloseModalClick}
        elementid={elementid}
      />
    </>
  );
};

export default FreeTextSettings;
