import { useState, useContext, useEffect } from "react";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { Controller, useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";

export const StarRatingUi = ({
  elementid,
  showHide,
  previewFlag = false,
  oppFields,
}) => {
  const { board } = useContext(boardConetxt);

  // Fetch settings for the element
  const ElementSetting = board.find(
    (item) => item?.elementid === elementid
  )?.settings;

  const { label, instructions, required, hidden } = ElementSetting || {};
  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };
  const disableElementForBuild = window.location.pathname.includes("build");

  const {
    formState: { errors },
    setValue,
    control,
  } = useFormContext();

  const [rating, setRating] = useState(0);

  // Sync oppFields with state and form value
  useEffect(() => {
    if (oppFields) {
      setValue(elementid, oppFields);
      setRating(oppFields);
    }
  }, [oppFields, elementid, setValue]);

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const updatedParamValue = params.get(elementid);

    if (elementid && updatedParamValue) {
      setValue(elementid, updatedParamValue);
      setRating(Number(updatedParamValue));
    }
  }, [elementid, setValue, location]);

  const normalizeFieldName = (name) => name.replace(/[.\s]/g, "_");

  return (
    <div
    className={`${(previewFlag && GetStyleClasses(elementid)) || ""} ${
      hidden && previewFlag ? "d-none" : ""
    }`}      id={elementid}
      style={initStyle}
      hidden={hidden && previewFlag}
    >
      <label>
        <span className="d-flex mb-1 align-items-center">
          <span>
            {label || "Rating"}
            {required && <span style={{ color: "red" }}>*</span>}
          </span>
        </span>
      </label>
      <div className="form-floating w-100 form-group">
        <Controller
          name={normalizeFieldName(elementid)}
          control={control}
          rules={{
            required: {
              value: required,
              message: "This field is required",
            },
          }}
          render={({ field }) => (
            <div className="star-rating">
              {[...Array(5)].map((_, index) => {
                const starValue = index + 1;
                return (
                  <button
                    type="button"
                    key={starValue}
                    disabled={disableElementForBuild}
                    className={starValue <= (rating || 0) ? "on" : "off"}
                    onClick={() => {
                      setRating(starValue);
                      field.onChange(starValue); // Sync with React Hook Form
                    }}
                    onMouseEnter={() => setRating(starValue)}
                    onMouseLeave={() => setRating(field.value || 0)}
                  >
                    <span className="star fs-4">&#9733;</span>
                  </button>
                );
              })}
            </div>
          )}
        />
      </div>

      <small className="text-secondary">{instructions}</small>
      <div className="d-flex flex-column gap-2">
        {errors[elementid] && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>
    </div>
  );
};
