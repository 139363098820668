import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import logo from "../../../assets/images/LOGO-BLUE.png";
import { Loaderr } from "../../../components/Loaderr";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Default Forms
import {AppointmentFormBuyer,AppointmentFormBuyerCondition} from "../../../components/form-builder-components/FormBuilderTemplates/DefaultForms/AppointmentFormBuyer"
import {AppointmentFormSeller,AppointmentFormSellerCondition} from "../../../components/form-builder-components/FormBuilderTemplates/DefaultForms/AppointmentFormSeller"
import {DispositionForm,DispositionFormConditions} from "../../../components/form-builder-components/FormBuilderTemplates/DefaultForms/Dissposition Form Template"
import {AgreementSignedBuyerForm,AgreementSignedBuyerFormCondition} from "../../../components/form-builder-components/FormBuilderTemplates/DefaultForms/AgreementSignedBuyerForm"
import {AgreementSignedSellerForm,AgreementSignedSellerFormCondition} from "../../../components/form-builder-components/FormBuilderTemplates/DefaultForms/AgreementSignedSellerForm"
import {PendingFormBuyer,PendingFormBuyerCondition} from "../../../components/form-builder-components/FormBuilderTemplates/DefaultForms/PendingFormBuyer"
import {PendingFormSeller,PendingFormSellerCondition} from "../../../components/form-builder-components/FormBuilderTemplates/DefaultForms/PendingFormSeller"
import {ClosedFormTemplatt} from "../../../components/form-builder-components/FormBuilderTemplates/DefaultForms/Closed Form Template"
//Whissel Realty Forms
import {whisselTeamAppointmentFormBuyer,whisselTeamAppointmentFormConditons} from "../../../components/form-builder-components/FormBuilderTemplates/WhisselTeamForms/whissel Team AppointmentForm Buyer"
import {whisselTeamAppointmentFormSeller,whisselTeamAppointmentFormSellerConditions} from "../../../components/form-builder-components/FormBuilderTemplates/WhisselTeamForms/whissel Team AppointmentForm Seller"
import {WhisselDispositionForm,WhisselDispositionConditions} from "../../../components/form-builder-components/FormBuilderTemplates/WhisselTeamForms/whissel Team Disposition_Form Template"
import {WhisselTeamAgreementFormBuyer,WhisselTeamAgreementFormBuyerCondition} from "../../../components/form-builder-components/FormBuilderTemplates/WhisselTeamForms/whissel Team Agreement Form Buyer"
import {WhisselTeamAgreementFormSeller,whisselTeamAgreementFormSellerCondition} from "../../../components/form-builder-components/FormBuilderTemplates/WhisselTeamForms/whissel Team Agreement Form Seller"
import {whisselTeamPendingFormBuyer,whisselTeamPendingFormBuyerCondition} from "../../../components/form-builder-components/FormBuilderTemplates/WhisselTeamForms/whissel Team Pending Form Buyer"
import {whisselTeamPendingFormSeller,whisselTeamPendingFormSellerConditions} from "../../../components/form-builder-components/FormBuilderTemplates/WhisselTeamForms/whissel Team Pending Form Seller"
import {WhisselClosedForm,WhisselCloseFormConditon} from "../../../components/form-builder-components/FormBuilderTemplates/WhisselTeamForms/whissel Team Closed Form_Form Template"



const GetAllFubData = gql`
query GetFubAllData($type: String!) {
  GetFubAllData(type: $type) {
    output
    status
    info
  }
}`

const teamSettings=gql`
query GetTeamSettings {
  GetTeamSettings {
    team_id
    FormBuilder
    AutomationBuilder
    show_whissel_form_templates
    customizable_leader_boards
  }
}`
const leadID=[
  {
    "elementid": "InputField-Follow-up-boss-lead-id",
    "type": "InputField",
    "name": "Follow Up Boss Lead ID",
    "field_sub_type": "opportunity",
    "settings": {
        "label": "Follow Up Boss Lead ID",
        "predefine": "",
        "size": "large",
        "required": false,
        "hidden": false,
        "minValue": "0",
        "maxValue": "",
        "instructions": ""
    }
}
]
function CreateForm() {
  const [selectedFields, setSelectedFields] = useState([]);

  const [newForm, setNewForm] = useState({
    name: "",
    description: "",
    form_template: "",
    useTemplate: "",
    form_type: "",
    pipelineName: "",
    StageName: "",
    Appointmentform:"no",
    leadformType:"",
  });
  ////console.log("newForm", WhisselAppointmentFormCondition )
  const navigate = useNavigate();
  const option = ["Choose", "Yes", "No"]
  const [form_template, setForm_template] = useState(null)
  const [template_condition, set_template_conditon] = useState(null)
  // const [template_condition, set_template_conditon] = useState()
  const [pipelineData, setPipelineData] = useState([]);
  // const templateOptions= ['Appointment_Form', 'Disposition_Form', 'Transaction_Form',
  //  "Whissel Team : Appointment_Form", "Whissel Team : Disposition_Form", 
  //  "Whissel Team : Agreement_Form", "Whissel Team : Pending_Close", "Whissel Team : Closed_Form"];
  const templateOptions = ['Appointment_Form_Buyer','Appointment_Form_Seller', 'Disposition_Form',"Agreement_Signed_Buyer_Form","Agreement_Signed_Seller_Form","Pending_Form_Buyer","Pending_Form_Seller", 'Closed_Form'];
  const whisselTemplateOptions = [
    "Whissel Realty : Appointment_Form_Buyer",
    "Whissel Realty : Appointment_Form_Seller",
    "Whissel Realty : Disposition_Form",
    "Whissel Realty : Agreement_Form_Buyer",
    "Whissel Realty : Agreement_Form_Seller",
    "Whissel Realty : Pending_Form_Buyer",
    "Whissel Realty : Pending_Form_Seller",
    "Whissel Realty : Closed_Form"
  ];
  const formtype = ["Opportunity Forms","Lead Forms"]
  const [maxplan, setMaxplan] = useState(null);

  const { data: teamSettingsData } = useQuery(teamSettings, {
    onCompleted: (data) => {
      // setMaxplan(data.GetTeamSettings.FormBuilder)
      setMaxplan(teamSettingsData?.GetTeamSettings[0]?.show_whissel_form_templates)

    }
  })

  



  const { data: GetingAllFubDataList } = useQuery(GetAllFubData, {
    variables: {
      type: "pipelines"
    },
  })

  useEffect(() => {
    if (GetingAllFubDataList) {
      setPipelineData(GetingAllFubDataList.GetFubAllData.output)
    }
  }, [GetingAllFubDataList])
  ////console.log("fubData", template_condition )

 useEffect(() => {
   if(newForm.form_type==="Lead Forms"){
    console.log("Lead Forms")
    setForm_template(leadID)
    set_template_conditon(null)
    }else if (newForm.useTemplate === "No"){
    setForm_template(null)
    set_template_conditon(null)
  }
 else if(newForm.form_template === "Appointment_Form_Buyer"){
    setForm_template(AppointmentFormBuyer)
    set_template_conditon(AppointmentFormBuyerCondition)
  }else if(newForm.form_template === "Appointment_Form_Seller"){
    setForm_template(AppointmentFormSeller)
    set_template_conditon(AppointmentFormSellerCondition)
  }else if(newForm.form_template === "Disposition_Form"){
    setForm_template(DispositionForm)
    set_template_conditon(DispositionFormConditions)
  }else if(newForm.form_template === "Agreement_Signed_Buyer_Form"){
    setForm_template(AgreementSignedBuyerForm)
    set_template_conditon(AgreementSignedBuyerFormCondition)
  }else if(newForm.form_template === "Agreement_Signed_Seller_Form"){
    setForm_template(AgreementSignedSellerForm)
    set_template_conditon(AgreementSignedSellerFormCondition)
  }else if(newForm.form_template === "Pending_Form_Buyer"){
    setForm_template(PendingFormBuyer)
    set_template_conditon(PendingFormBuyerCondition)
  }else if(newForm.form_template === "Pending_Form_Seller"){
    setForm_template(PendingFormSeller)
    set_template_conditon(PendingFormSellerCondition)
  }else if(newForm.form_template === "Closed_Form"){
    setForm_template(ClosedFormTemplatt)
    set_template_conditon([])
  }else if(newForm.form_template === "Whissel Realty : Appointment_Form_Buyer"){
    setForm_template(whisselTeamAppointmentFormBuyer)
    set_template_conditon(whisselTeamAppointmentFormConditons)
  }else if(newForm.form_template === "Whissel Realty : Appointment_Form_Seller"){
    setForm_template(whisselTeamAppointmentFormSeller)
    set_template_conditon(whisselTeamAppointmentFormSellerConditions)
  }else if(newForm.form_template === "Whissel Realty : Disposition_Form"){
    setForm_template(WhisselDispositionForm)
    set_template_conditon(WhisselDispositionConditions)
  }else if(newForm.form_template === "Whissel Realty : Agreement_Form_Buyer"){
    setForm_template(WhisselTeamAgreementFormBuyer)
    set_template_conditon(WhisselTeamAgreementFormBuyerCondition)
  }else if(newForm.form_template === "Whissel Realty : Agreement_Form_Seller"){
    setForm_template(WhisselTeamAgreementFormSeller)
    set_template_conditon(whisselTeamAgreementFormSellerCondition)
  }else if(newForm.form_template === "Whissel Realty : Pending_Form_Buyer"){
    setForm_template(whisselTeamPendingFormBuyer)
    set_template_conditon(whisselTeamPendingFormBuyerCondition)
  }else if(newForm.form_template === "Whissel Realty : Pending_Form_Seller"){
    setForm_template(whisselTeamPendingFormSeller)
    set_template_conditon(whisselTeamPendingFormSellerConditions)
  }else if(newForm.form_template === "Whissel Realty : Closed_Form"){
    setForm_template(WhisselClosedForm)
    set_template_conditon(WhisselCloseFormConditon)
  }
  
 }, [newForm.form_template,newForm.useTemplate,newForm.useTemplate,newForm.form_type])
  //console.log("template_condition", newForm.form_template === "Whissel_Realty : Appointment_Form_Buyer",newForm.form_template,newForm.useTemplate)


  const CreateFormApi = gql`
  mutation CreateForm($formData: GraphQLJSON) {
    CreateForm(form_data: $formData) {
      team_id
      form_id
    }
  }
  `;

  const [creatForm, { loading:CreateFormLoading }] = useMutation(CreateFormApi, {
    onError: (error) => {
      ////console.log("error", error);
      toast.error('Failed To Fetch , Form Not Created ', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        // transition: Bounce,
      });
    },

    onCompleted: (data) => {
      const form_id = data.CreateForm.form_id;
      navigate(`/forms/build/${form_id}`);
    },
  });

  const [assignedAgent, setAssignedAgent] = useState('');

  // Step 2: Handle onChange event
  const handleAssignedAgentChange = (e) => {
    setAssignedAgent(e.target.value);
  };



  
  
  const inputHandler = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setNewForm({ ...newForm, [name]: value });
  };
  // ////console.log("SEt", set_template_conditon == null ? [] : set_template_conditon)
  const handleSubmit = (e) => {
    e.preventDefault();
    
    creatForm({
      variables: {
        formData: {
          form_name: newForm.name,
          form_description: newForm.description,
          base_form_template: newForm.form_template,
          form_fields: form_template == null ? [] : form_template.map((item) => item?.elementid),
          field_settings_data: form_template == null ? [] : form_template,
          form_conditions: template_condition == null ? [] : template_condition,
          form_type: newForm.form_type,
          useTemplate: newForm.useTemplate,
          pipeline: newForm.pipelineName,
          stage: newForm.StageName,
          is_this_appt_form: newForm.Appointmentform ,
          status: false,
          selected_primary_fields: selectedFields,
          assigned_agent: assignedAgent,
          lead_form_type:newForm.leadformType
        },
      },
    })
  };

  // Handle checkbox change
  const handleFieldChange = (e, item) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add the field to the selectedFields array
      setSelectedFields([...selectedFields, item.elementid]);
    } else {
      // Remove the field from the selectedFields array
      setSelectedFields(
        selectedFields.filter((fieldName) => fieldName !== item.elementid)
      );
    }
  };


  //console.log("selectedFields", selectedFields);
// 
  const fieldOptions = [
    { id: 1, name: "Price", description: "Linked To FUB Transaction Price",elementid:"NumberField-opp_price" },
    { id: 2, name: "Lead Last Name", description: "Linked To Lead Last Name",elementid:"InputField-opp_last_name" },
    { id: 4, name: "Address", description: "Linked To FUB Address" ,elementid:"InputField-opp_address" },
    { id: 13, name: "Address 2", description: "Linked To Address2",elementid:"InputField-opp_address2" },
    { id: 3, name: "Close Date", description: "Linked To Close Date",elementid:"Date-opp_forecasted_close_date" },
    { id: 5, name: "App Description", description: "Linked To App Description ",elementid:"TextArea-App-Description" },
    { id: 6, name: "Commission", description: "Linked To Commission",elementid:"NumberField-commission" },
    { id: 6, name: "Agent Split", description: "Linked To Agent Split",elementid:"NumberField-agent_split" },
    { id: 6, name: "Team Split", description: "Linked To Team Split",elementid:"NumberField-team_split" },
];


const AppointmentFieldOption=[
  { id: 1, name: "Appointment Date", description: "Linked To Appointment Date",elementid:"Date-opp_appt_date" },
  { id: 2, name: "Appointment Time", description: "Linked To Appointment Time",elementid:"DropDown-fub_appt_start_time" },
  {id: 7,name:"Appointment Location",description:"Linked To Appointment Location",elementid:"DropDown-Appt-Location"},
  { id: 3, name: "Appointment Type", description: "Linked To Appointment Type",elementid:"DropDown-app-type"},

]
const leadformType=[{
  id: 1, name: "Create Lead Form",value:"create_lead_form",iconInfo:" Create Lead Forms will live outside of follow up boss and will be used to create new leads in follow up boss"
},
{
  id: 2, name: " Update Lead Form",value:"update_lead_form",iconInfo:"Update lead forms will live in each follow up boss lead record and can be used to update that lead."
}]

const AppointmentFieldValue=[{
  id: 1, name: "Yes appointment form",value:"yes_appt_form"
},
{
  id: 2, name: "Yes appointment disposition form",value:"yes_disp_form"
},
{
  id: 3, name: "No",value:"no"
}]
  // //console.log("><", newForm.form_type === "Opportunity Forms" && newForm.useTemplate === "No" && newForm.Appointmentform === "Yes appointment form" || "Yes appointment disposition form" )
  
  
  if (CreateFormLoading) return <Loaderr />;
  return (
    <>
      {/* {CreateFormLoading && <Loaderr />} */}
      <ToastContainer />

      <header className="header bg-white border-bottom" id="header">
        <div>
          <div className="pointer" onClick={() => navigate("/forms/")}>
            <div className="d-flex align-items-center ms-2">
              <span className="navbar-brand m-0 p-0">
                <img className="img-fluid" src={logo} width="30" alt="" />
              </span>
              <i className="bx bx-chevron-left nav_icon mx-md-2 mx-1"></i>
              <span className="p-0 m-0 text-decoration-underline">Form Dashboard</span>
            </div>
          </div>
        </div>
      </header>
      <div className="main container mx-auto" id="body-pd">
        <div
          className="d-lg-none alert alert-warning banner-top p-3 py-2 rounded-0 m-0"
          role="alert"
        >
          <div className="d-flex flex-wrap align-items-center">
            <div className="d-flex align-items-center my-2">
              <i className="bx bx-mobile fs-5" />
              <i className="bx bx-arrow-to-right me-2 fs-5" />
              <i className="bx bx-desktop me-2 fs-5" />
            </div>
            <span className="my-2">
              Please switch to desktop and optimize this view. Smaller screens
              are not efficient.
            </span>
          </div>
        </div>
        <section className="col-xl-7 mx-auto">
          <div className="bg-white p-4 rounded-3 border">
            <h5>New Form</h5>
            <p className="text-secondary mt-2">
              Add a title and description for your form
            </p>
            <form className="mt-lg-5 mt-4" action="" onSubmit={handleSubmit}>
              <div className="mb-4">
                <div className="form-floating w-100 form-group">
                  <input
                    placeholder="Workspace title "
                    className="form-control input-bn"
                    type="text"
                    required
                    maxLength={70}
                    name="name"
                    value={newForm.name}
                    onChange={inputHandler}
                  />
                  <label>
                    <span className="d-flex align-items-center">
                      <i
                        className="iconify me-1 mr-1 fs-5"
                        data-icon="ri:flow-chart"
                      />
                      <span>Form Name</span>
                    </span>
                  </label>
                </div>
                {/* placeholder for errors */}
              </div>
              <div className="mb-4">
                <div className="form-floating w-100 form-group">
                  <textarea
                    placeholder="Description "
                    className="form-control input-bn"
                    type="text"
                    // required
                    maxLength={100}
                    name="description"
                    value={newForm.description}
                    onChange={inputHandler}
                  />
                  <label>
                    <span className="d-flex align-items-center">
                      <i
                        className="iconify me-1 mr-1 fs-5"
                        data-icon="mdi:text"
                      />
                      <span>Form Description</span>
                    </span>
                  </label>
                </div>
              </div>

              <div className="mb-4">
                <div className="form-floating w-100 form-group">
                  <select
                    className="form-select input-bn"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    name="form_type"
                    required
                    value={newForm.form_type}
                    onChange={inputHandler}
                  >
                    <option value="">Choose Form type</option>
                    {formtype?.map((item, index) => {
                      return (
                        <option key={index} value={item}>{item}</option>
                      )
                    })}
                  </select>
                  <label htmlFor="floatingSelect">Select Form Type</label>
                </div>
              </div>

              {/* {/* Temo */}

               {newForm.form_type === "Opportunity Forms" && (
        <div className="mb-4">
          <div className="form-floating w-100 form-group">
            <select
              className="form-select input-bn"
              placeholder="Please Select"
              name="assignedAgent"
              value={assignedAgent} // Step 3: Connect state to the input
              onChange={handleAssignedAgentChange} // Step 3: Connect onChange to the input
              required
            >
              <option value="" disabled selected hidden>Select an option</option>
              <option value="fub_assigned_agent">Use The assigned agent on the FUB lead record</option>
              <option value="assigned_agent_field">Use an Assigned agent field to assign the FUB lead and Deal.</option>
            </select>
            <label>
              <span className="d-flex align-items-center">
                <i
                  className="iconify me-1 mr-1 fs-5"
                  data-icon="mdi:text"
                />
                <span>
                  Assigned agents   
                </span>
              </span>
            </label>

            <div className="d-flex justify-content-end">
              <div className="tooltip-container">
                <i className="iconify text-secondary fs-5" data-icon="mdi:info"/>
                <div className="tooltip-text text-start form-tooltip p-3">
                  Use The assigned agent on the FUB lead record means when the form is submitted 
                  we will use the assigned agent on the lead record as the assigned agent on the deal record.<br/> <br/>
                  Use an Assigned agent field to assign the FUB lead and Deal. 
                  means whichever agent is selected on the Outside agent field will be assigned to the lead record and the deal record. 
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
              {newForm.form_type === "Opportunity Forms" && (<div className="mb-4">
                <div className="form-floating w-100 form-group">
                  <select
                    className="form-select input-bn"
                    placeholder="Please Select"
                    //onChange={inputHandler}
                    onChange={inputHandler}
                    name="pipelineName"
                    required
                  >
                    <option value="" disabled selected hidden>Choose</option>
                    {
                      pipelineData?.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>{item.name}</option>
                        )
                      }
                      )
                    }
                  </select>
                  <label>
                    <span className="d-flex align-items-center">
                      <i
                        className="iconify me-1 mr-1 fs-5"
                        data-icon="mdi:text"
                      />
                      <span>Select pipeline</span>
                    </span>
                  </label>

                </div>
              </div>
              )
              }
              

              {newForm.form_type === "Opportunity Forms" && (<div className="mb-4">
                <div className="form-floating w-100 form-group">
                  <select
                    className="form-select input-bn"
                    placeholder="Please Select"
                    //onChange={inputHandler}
                    onChange={inputHandler}
                    name="StageName"
                    required
                  >
                    <option value="" disabled selected hidden>Choose</option>
                    {
                      pipelineData?.map((item) => {
                        ////console.log("item", item,item.id, newForm.pipelineName)
                        if (+item.id === +newForm.pipelineName) {
                          return (
                            item.stages.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>{item.name}</option>
                              )
                            })
                          )
                        }
                      }
                      )
                    }
                  </select>
                  <label>
                    <span className="d-flex align-items-center">
                      <i
                        className="iconify me-1 mr-1 fs-5"
                        data-icon="mdi:text"
                      />
                      <span>SelectStage</span>
                    </span>
                  </label>

                </div>
              </div>)


              }

              {/* //template */}


                {
                newForm.form_type === "Opportunity Forms" && (
                  <div className="mb-4">
                      <div className="form-floating w-100 form-group">
                        <select
                          className="form-select input-bn"
                          placeholder="Please Select"
                          //onChange={inputHandler}
                          onChange={inputHandler}
                          name="Appointmentform"
                          defaultValue={newForm.Appointmentform}
                        >
                          {/* yes_appt_form, yes_disp_form, no */}
                          <option value="" disabled hidden>Choose</option>
                          {/* <option value="yes_appt_form">Yes appointment  form</option>
                          <option value="yes_disp_form">Yes appointment disposition form</option>
                          <option value="no" selected>No</option> */}
                          {
                            AppointmentFieldValue.map((item, index) => {
                              return (
                                <option key={item.id} value={item.value}>{item.name}</option>
                              )
                            }
                            )
                          }



                        </select>



                        <label>
                          <span className="d-flex align-items-center">
                            <i
                              className="iconify me-1 mr-1 fs-5"
                              data-icon="mdi:text"
                            />
                            <span>Is this is an appointment form ?</span>
                          </span>
                        </label>

                      </div>
                    </div>
                )
                }
               {
  newForm.form_type === "Lead Forms" && (
    <div className="mb-4">
      <div className="form-floating w-100 form-group">
        <select
          className="form-select input-bn"
          placeholder="Please Select"
          onChange={inputHandler}
          name="leadformType"
          defaultValue={newForm.leadformType}
        >
          <option value="" disabled hidden>
            Choose Lead Form Type
          </option>
          {leadformType.map((item) => (
            <option key={item.id} value={item.value}>
              {item.name}
            </option>
          ))}
        </select>

        <label>
          <span className="d-flex align-items-center">
            <i
              className="iconify me-1 mr-1 fs-5"
              data-icon="mdi:text"
            />
            <span>Lead Form Type</span>
          </span>
        </label>

        <div className="d-flex justify-content-end">
          <div className="tooltip-container">
            <i
              className="iconify text-secondary fs-5"
              data-icon="mdi:info"
            />
            <div className="tooltip-text text-start form-tooltip p-3">
              {
                leadformType.find(
                  (item) => item.value === newForm.leadformType
                )?.iconInfo || "Please select a lead form type."
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}



              {
                newForm.form_type === "Opportunity Forms" && (
                  <>
                    <div className="mb-4">
                      <div className="form-floating w-100 form-group">
                        <select
                          className="form-select input-bn"
                          placeholder="Please Select"
                          //onChange={inputHandler}
                          onChange={inputHandler}
                          name="useTemplate"
                        >
                          <option value="" disabled selected hidden>Choose</option>
                          {
                            option.map((item, index) => {
                              return (
                                <option key={index} value={item}>{item}</option>
                              )
                            }
                            )
                          }
                        </select>






                        <label>
                          <span className="d-flex align-items-center">
                            <i
                              className="iconify me-1 mr-1 fs-5"
                              data-icon="mdi:text"
                            />
                            <span>Do You want to Use Templates</span>
                          </span>
                        </label>

                      </div>
                    </div>

                  
                    {newForm.useTemplate === "No" && (newForm.Appointmentform == "no")&& (
       <div className="mb-4">
       <h6>Primary fields</h6>
       <p>
         Select primary fields you want to pre-add to your form. NB you can add
         them later in the form builder.
       </p>
       {fieldOptions.map((item, index) => (
         <div key={index} className="form-check">
           <input
             className="form-check-input mt-2"
             type="checkbox"
             id={`flexSwitchCheckDefault${index}`}
             onChange={(e) => handleFieldChange(e, item)}
             checked={selectedFields.includes(item.elementid)}
           />
           <label
             className="form-check-label pt-2"
             htmlFor={`flexSwitchCheckDefault${index}`}
           >
             {item.name}{" "}
             <small className="text-secondary ml-1">({item.description})</small>
           </label>
         </div>
       ))}
     </div>
      )}
       {
                newForm.form_type === "Opportunity Forms" && newForm.useTemplate === "No" && (newForm.Appointmentform === "yes_appt_form") &&  (

                  <div className="mb-4">
                  <h6>Appointment Form Primary fields</h6>
                  <p>
                    Select primary fields you want to pre-add to your Appointment form. NB you can add
                    them later in the form builder.
                  </p>
                  {AppointmentFieldOption.map((item, index) => (
                    <div key={index} className="form-check">
                      <input
                        className="form-check-input mt-2"
                        type="checkbox"
                        id={`flexSwitchCheckDefault${index}`}
                        onChange={(e) => handleFieldChange(e, item)}
                        checked={selectedFields.includes(item.elementid)}
                      />
                      <label
                        className="form-check-label pt-2"
                        htmlFor={`flexSwitchCheckDefault${index}`}
                      >
                        {item.name}{" "}
                        <small className="text-secondary ml-1">({item.description})</small>
                      </label>
                    </div>
                  ))}
                </div>
                )
                
                }


                    {newForm.useTemplate === "Yes" &&
                      <div className="mb-4">
                        <div className="form-floating w-100 form-group">

                          <select
                            className="form-select input-bn"
                            placeholder="Please Select"
                            onChange={inputHandler}
                            name="form_template"
                          >
                            <option value="" disabled selected hidden>Choose Template</option>
                            {templateOptions.map((option, index) => {
                              const optionValue = option.replace(/_/g, " ");
                              ////console.log("option", optionValue);
                              return <option key={index} value={option}>{optionValue}</option>;
                            })}
                            {maxplan && whisselTemplateOptions.map((option, index) => (
                              <option key={index} value={option}>{option.replace(/_/g, " ")}</option>
                            ))}
                          </select>
                          <label>
                            <span className="d-flex align-items-center">
                              <i
                                className="iconify me-1 mr-1 fs-5"
                                data-icon="mdi:text"
                              />
                              <span>Choose Template</span>
                            </span>
                          </label>
                        </div>
                      </div>
                    }
                  </>)
              }
             
              


              <div className="d-flex justify-content-end">
                <button

                  type="submit"
                  className="d-flex align-items-center btn btn-primary"
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </>
  );
}

export default CreateForm;
