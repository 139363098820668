import React, { useContext, useEffect } from "react";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { Controller, useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";

export const NumberFieldUi = ({
  elementid,
  oppFields,
  showHide,
  previewFlag = false,
}) => {
  const { board } = useContext(boardConetxt);
  const ElementSettingsData =
    board?.find((item) => String(item?.elementid) === String(elementid))
      ?.settings || {};

  const {
    label,
    inputType,
    instructions,
    minValue,
    maxValue,
    predefine,
    required,
    hidden,
  } = ElementSettingsData;

  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };

  const disableElementForBuild = window.location.pathname.includes("build");

  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext({
    mode: "onFocus",
    reValidateMode: "onChange",
  });
  const Location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(Location.search);
    const updatedParamValue = params.get(elementid);

    if (elementid && updatedParamValue) {
      setValue(elementid, updatedParamValue);
    }
  }, [elementid, setValue]);
  useEffect(() => {
    if (oppFields) {
      setValue(elementid, oppFields);
    }
  }, [oppFields, elementid, setValue]);

  const ElementName = board?.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.name;

  const checkingValidation = () => {
    if (Boolean(required) === true && String(showHide) === "show") {
      return false;
    } else if (Boolean(required) === false && String(showHide) === "show") {
      return false;
    } else if (Boolean(required) === true && String(showHide) === "hide") {
      return false;
    } else if (Boolean(required) === false && String(showHide) === "hide") {
      return false;
    } else if (Boolean(required) === true && hidden === true) {
      return false;
    } else if (Boolean(required) === true) {
      return true;
    } else {
      return false;
    }
  };

  const handleInputChange = (event, field) => {
    let value = event.target.value;

    // Ensure proper handling of floating point numbers and prevent rounding issues
    value = value?.replace(/[^0-9.]/g, ""); // Removes non-numeric characters except for '.'

    if (!isNaN(value)) {
      field.onChange(parseFloat(value)); // Always handle input as a float
    }
  };

  const normalizeFieldName = (name) => {
    return name?.replace(/[.\s]/g, "_"); // Replace dots and spaces with underscores
  };

  const handleKeyDown = (event) => {
    const key = event.key;

    if (
      !/[0-9]/.test(key) &&
      key !== "Backspace" &&
      key !== "Tab" &&
      key !== "ArrowLeft" &&
      key !== "ArrowRight" &&
      key !== "."
    ) {
      event.preventDefault();
    }
  };
  // Prevent mouse scroll wheel from changing number input value
  const preventWheelChange = (e) => {
    if (document.activeElement === e.target) {
      e.preventDefault(); // Stop scrolling if input is focused
    }
  };

  useEffect(() => {
    const inputElement = document.getElementById(elementid);

    // Add event listener to prevent scroll on focus
    if (inputElement) {
      inputElement.addEventListener("wheel", preventWheelChange);
    }

    return () => {
      // Clean up event listener
      if (inputElement) {
        inputElement.removeEventListener("wheel", preventWheelChange);
      }
    };
  }, [elementid]);
  // const preventScroll = (e) => {
  //   e.preventDefault();
  // };
  return (
    <div
      className={`${(previewFlag && GetStyleClasses(elementid)) || ""} ${
        hidden && previewFlag ? "d-none" : ""
      }`}
      id={elementid}
      style={initStyle}
      hidden={hidden && previewFlag}
    >
      <label>
        <span className="d-flex mb-2 align-items-center">
          {label === undefined || label === "" ? ElementName : label}
          {required ? <span style={{ color: "red" }}>*</span> : ""}
        </span>
      </label>
      <div className="w-100 form-group">
        {Object.keys(ElementSettingsData)?.length === 0 ? (
          <Controller
            control={control}
            name={normalizeFieldName(elementid)}
            rules={{
              maxLength: {
                value: 13,
                message: `Please Enter less than 13 Characters`,
              },
              pattern: {
                value: /^\d+(\.\d+)?$/,
                message: "Please Enter Number Only",
              },
            }}
            render={({ field }) => (
              <input
                type="number"
                className="form-control input-bn"
                placeholder="Enter Number"
                disabled={disableElementForBuild}
                onChange={(e) => handleInputChange(e, field)} // Sanitize input
                onKeyDown={handleKeyDown} // Prevent input when Num Lock is off
                {...field}
              />
            )}
          />
        ) : (
          <Controller
            control={control}
            name={normalizeFieldName(elementid)}
            rules={{
              required: {
                value: checkingValidation(),
                message: "This Field is Required",
              },
              minLength: {
                value: minValue ? minValue : 1,
                message: `Please Enter more than ${
                  minValue ? minValue : 1
                } Characters`,
              },
              maxLength: {
                value: maxValue ? maxValue : 13,
                message: `Please Enter less than ${
                  maxValue ? maxValue : 13
                } Characters`,
              },
              pattern: {
                value: /^\d+(\.\d+)?$/,
                message: "Please Enter Number Only",
              },
              validate: (value) =>
                value !== -1 || "Invalid input: cannot be -1",
            }}
            render={({ field }) => (
              <input
                type={inputType || "number"}
                className="form-control input-bn"
                placeholder="Enter Number"
                disabled={disableElementForBuild}
                defaultValue={predefine || ""}
                onChange={(e) => handleInputChange(e, field)} // Sanitize input
                onKeyDown={handleKeyDown} // Prevent input when Num Lock is off
                // onWheel={(e) => preventScroll(e)}
                {...field}
              />
            )}
          />
        )}
      </div>
      <small className="text-secondary">{instructions}</small>
      <div className="d-flex flex-column gap-2">
        {errors && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>
    </div>
  );
};
