import React, { useContext } from "react";
import { boardConetxt } from "../../../../pages/form-builder/dashboard/ContextHelper";
import { gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";


const UpdateFormFields =gql`
mutation UpdateField($fieldId: String, $formId: String, $fieldData: GraphQLJSON) {
  UpdateField(field_id: $fieldId, form_id: $formId, field_data: $fieldData) {
    field_id
  }
}`

export function ModalFooter({
  ElementSettingData,
  handleCloseModalClick,
  elementid,
  showErrorInFooter,
}) {
  const params=useParams();
  const { board, setboard } = useContext(boardConetxt);
  const [UpdateField] = useMutation(UpdateFormFields);

  const handleFormSettingSubmit = (e) => {
    e.preventDefault();

    const updatedBoard = board?.map((item) => {
      const obj = { ...item };
      if (String(obj?.elementid) === String(elementid)) {
        obj.settings = ElementSettingData;

        const fieldData = {
          input_type: item?.type,
          settings: ElementSettingData,
        };

        if (ElementSettingData?.label?.trim()) {
          fieldData.field_name = ElementSettingData?.label;
          UpdateField({
            variables: {
              fieldId: elementid,
              formId: params?.id,
              fieldData
            }
          });
        }
      }
     
      return obj;
    });
   
    


    const uniqueItems = Array.from(
      updatedBoard
        ?.reduce((map, item) => {
          if (item?.elementid) map?.set(item?.elementid, item);
          return map;
        }, new Map())
        ?.values()
    );

    setboard(uniqueItems);

    handleCloseModalClick(e);
  };

  return (
    <>
      <div className="modal-footer">
        <button
          type="button"
          onClick={(e) => {
            handleCloseModalClick(e);
          }}
          className="btn btn-primary-outline"
        >
          Close
        </button>

        <button
          type="button"
          onClick={handleFormSettingSubmit}
          className="btn btn-primary"
          disabled={
            showErrorInFooter && ElementSettingData?.options?.length > 0
          }
        >
          Save changes
        </button>
      </div>

      {showErrorInFooter && ElementSettingData?.options?.length > 0 && (
        <div className="d-flex justify-content-end">
          <p className="text-danger me-3">
            Please fill the value in the blank option
          </p>
        </div>
      )}
    </>
  );
}
