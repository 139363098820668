import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { makeApiCall } from "../../../modules/MakeApiRequest";
import { WorkFlowSessionInfo } from '../../../pages/automation-builder/workflows/EditWorkflow'
import { generatekeys } from "../../../utils/parser";

const TestTrigger = ({ trigger_id, setTriggerDetails, triggerdetails, setTestTrigger, set_test_result, setShowDetails, selected_action, testAgain }) => {
  const { workflow_id } = useParams();
  const { session, setsession } = useContext(WorkFlowSessionInfo);
  const [testTriggerOutput, setTestTriggerOutput] = useState(session ? session?.trigger?.action_output : []);
  const [outputArray, setOutputArray] = useState([]);
  const [testActionStatus, setTestActionStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (testAgain) {
      if (trigger_id) {
        makeApiCall("POST", "user/test_action/", {
          "action_id": "trigger",
          "trigger_id": trigger_id,
          "workflow_id": workflow_id
        }).then((data) => {
          setTestTriggerOutput(data?.action_output);
          if (data?.action_output?.errorMessage) {
            setTestTriggerOutput({ Error: data?.action_output?.errorMessage })
          }
          else if (data?.action_info?.error) {
            setTestTriggerOutput({ Error: data?.action_info?.error })
          }
          else {
            setTestTriggerOutput(data?.action_output)
          }
          setTestActionStatus(data?.action_status);
          set_test_result(data?.action_status)
          setsession({ ...session, trigger: data })
        });
      }
    }
  }, [testAgain]);

  useEffect(() => {
    setTriggerDetails({ ...triggerdetails, test_status: testActionStatus })
  }, [testActionStatus]);

  const handleOutput = () => {
    let keys = generatekeys({ trigger: testTriggerOutput })
    setOutputArray(keys?.trigger)
  };

  useEffect(() => {
    handleOutput();
  }, [testTriggerOutput, selected_action, testAgain]);

  const testAction = () => {
    let test = "Loading...!"
    if (testActionStatus === "FAILED") {
      test = "Test failed!"
    } else if (testActionStatus === undefined) {
      test = "Test Failed Please Test Again"
    } else if (testActionStatus === "SUCCESS") {
      test = "Test successful!"
    }
    test = testAgain ? test : session?.trigger?.action_status === "SUCCESS" ? "Test successful!" : "Test failed!"
    return test
  }

  const filteredOutputArray = outputArray.filter(item =>
    (item.key_show?.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (item.value && item.value.toString().toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <>
      <div className="border rounded mt-3">
        <div className="modal-header p-3 pb-0">
          <h6 className="modal-title fs-6">Test Details</h6>
        </div>
        <div className="p-3">
          <div className={`badge ${(() => {
            if (testAction() === "Test successful!") {
              return "bg-success";
            } else if (testAction() === "Loading...!") {
              return "bg-dark";
            } else {
              return "bg-danger";
            }
          })()} mb-2 fw-light rounded-pill px-3`}>
            {testAction()}
          </div>

          {/* Search Box */}
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search output..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div className="p-3 border bg-color-1 mb-3 rounded-3 text-trigger-box">
            {(() => {
              if (filteredOutputArray.length > 0) {
                if (testAction() === "Loading...!") {
                  return <div className="m-0 ps-2 small border-start border-3 border-primary">Fetching data...</div>;
                } else {
                  return (
                    <div className="m-0 ps-2 small border-start border-3 border-primary">
                      {filteredOutputArray.map((item) => <p key={item.key_show}>{item.key_show + ": " + item.value}</p>)}
                    </div>
                  );
                }
              } else {
                if (searchTerm) {
                  return <div>Results not found.</div>;
                } else {
                  return <div>Something went wrong.</div>;
                }
              }
            })()}
          </div>
        </div>
      </div>
    </>
  );
};

export default TestTrigger;
