import React, { useEffect, useContext } from "react";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { Controller, useFormContext } from "react-hook-form";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { useLocation } from "react-router-dom";

export function ContactUi({
  elementid,
  showHide,
  oppFields,
  previewFlag = false,
}) {
  const { board } = useContext(boardConetxt);
  const {
    formState: { errors },
    setValue,
    control,
  } = useFormContext();
  const disableElementForBuild = window.location.pathname?.includes("build");
  const Location = useLocation();

    useEffect(() => {
      const params = new URLSearchParams(Location.search);
      const updatedParamValue = params.get(elementid);
    
      if (elementid && updatedParamValue) {
        setValue(elementid, updatedParamValue);
      }
    }, [elementid, setValue]);

  useEffect(() => {
    if (oppFields) {
      setValue(elementid, oppFields);
    }
  }, [oppFields, elementid, setValue]);

  const ElementSettingsData = board?.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.settings;
  const Elemlabel = board?.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.name;
  const {
    inputType,
    label,
    instructions,
    minValue,
    maxValue,
    predefine,
    required,
    hidden,
  } = ElementSettingsData;
  const initialStyle = {
    display: showHide === "show" ? "none" : "block",
  };

  const checkingValidation = () => {
    if (Boolean(required) === true && String(showHide) === "show") {
      return false;
    } else if (Boolean(required) === false && String(showHide) === "show") {
      return false;
    } else if (Boolean(required) === true && String(showHide) === "hide") {
      return false;
    } else if (Boolean(required) === false && String(showHide) === "hide") {
      return false;
    } else if (Boolean(required) === true && hidden === true) {
      return false;
    } else if (Boolean(required) === true) {
      return true;
    } else {
      return false;
    }
  };

  const normalizeFieldName = (name) => {
    return name?.replace(/[.\s]/g, "_"); // Replace dots and spaces with underscores
  };

  return (
    <div
      id={elementid}
      className={`${(previewFlag && GetStyleClasses(elementid)) || ""} ${
        hidden && previewFlag ? "d-none" : ""
      }`}      style={initialStyle}
      hidden={hidden && previewFlag}
    >
      <label>
        <span className="d-flex mb-2 align-items-center">
          <i className="iconify me-1 mr-1 fs-5" data-icon="carbon:phone" />
          <span>
            {label === undefined || label === "" ? Elemlabel : label}

            {required ? <span style={{ color: "red" }}>*</span> : ""}
          </span>
        </span>
      </label>
      <div className="w-100 form-group">
        {Object.keys(ElementSettingsData).length === 0 ? (
          <Controller
            control={control}
            name={normalizeFieldName(elementid)}
            rules={{
              maxLength: {
                value: 10,
                message: `Please Enter Less then  10 Characters`,
              },
              pattern: {
                value: /^[0-9]*$/,
                message: "Only numbers are allowed",
              },
            }}
            render={({ field }) => (
              <input
                type="number"
                className="form-control input-bn"
                placeholder="Contact"
                disabled={disableElementForBuild}
                {...field}
              />
            )}
          />
        ) : (
          <Controller
            control={control}
            name={normalizeFieldName(elementid)}
            rules={{
              required: {
                value: checkingValidation(),
                message: "This field is required",
              },
              minLength: {
                value: String(minValue) === " " ? 10 : minValue,
                message: `Please Enter More then ${
                  String(minValue) === " " ? 10 : minValue
                } Characters`,
              },
              maxLength: {
                value: String(maxValue) === "" || undefined ? 10 : maxValue,
                message: `Please Enter Less then ${
                  String(maxValue) === "" ? 10 : maxValue
                }   Characters`,
              },
              pattern: {
                value: /^[0-9]*$/,
                message: "Only numbers are allowed",
              },
            }}
            defaultValue={predefine === undefined ? "" : predefine}
            render={({ field }) => (
              <input
                type={inputType ? inputType : "number"}
                className="form-control input-bn"
                placeholder="Contact"
                disabled={disableElementForBuild}
                {...field}
              />
            )}
          />
        )}

        <small className="text-secondary">{instructions}</small>
        <div className="d-flex flex-column gap-2">
          {errors && (
            <span className="text-danger">{errors[elementid]?.message}</span>
          )}
        </div>
      </div>
    </div>
  );
}
