import React, { useState } from "react";
import { ModalFooter } from "./ModalFooter";

const NumberFieldSettings = ({
  elementid,
  board,
  maxLengthofLabel,
  handleCloseModalClick,
  elementSettingsData,
}) => {
  const ElementName = board?.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.name;
  const initialvalue = {
    label: ElementName,
    predefine: "",
    size: "large",
    inputType: "number",
    required: false,
    // duplicate: true,
    minValue: "",
    maxValue: "",
    instructions: "",
  };

  const showingFieldId = /\d/.test(elementid);

  const [NumberField, setNumberField] = useState(
    Object.keys(elementSettingsData)?.length !== 0
      ? elementSettingsData
      : initialvalue
  );

  const handleInputChange = (e) => {
    const name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (String(name) === "label" && value.trim() === "") {
      value = ElementName; // Set the default value to the element name
    }
    setNumberField({ ...NumberField, [name]: value });
  };

  return (
    <>
      <div className="modal-body py-4 px-lg-5 p-md-4">
        <h5 className="mb-4">
          <i
            className="iconify fs-5 pointer me-1"
            data-icon="tabler:checkbox"
          />
          Field Settings
        </h5>
        <div className="mb-4">
          <label>
            <span className="d-flex mb-2 align-items-center">
              <span>Label Field</span>
            </span>
          </label>
          <input
            placeholder="Placeholder for your field"
            defaultValue={
              NumberField?.label === "" ? ElementName : NumberField?.label
            }
            name="label"
            onBlur={handleInputChange}
            className="form-control input-bn"
            type="text"
            maxLength={maxLengthofLabel}
          />
          {/* placeholder for errors */}
        </div>
        <div className="mb-4">
          <label>
            <span className="d-flex mb-2 align-items-center">
              <span>Predefined Value</span>
            </span>
          </label>
          <input
            placeholder="Leave empty if you dont have a default value"
            className="form-control input-bn"
            name="predefine"
            type={NumberField?.inputType || "number"}
            defaultValue={NumberField?.predefine || ""}
            onBlur={handleInputChange}
            maxLength="20"
            minLength="20"
          />
          {/* placeholder for errors */}
        </div>

        <hr className="hr my-5" />
        <h5 className="mb-4">
          <i className="iconify fs-5 pointer me-1" data-icon="bx:slider" />
          General Settings
        </h5>
        {/* dropdowns----------- */}
        <div className="mt-4">
          <div className="mb-4">
            <label className="w-100">
              <span className=" mb-2 d-flex justify-content-between align-items-center">
                <span className="me-2">Size</span>
              </span>
            </label>
            <select
              className="form-select input-bn"
              value={NumberField?.size || ""}
              name="size"
              onChange={handleInputChange}
            >
              <option value="large">Large</option>
              <option value="medium">Medium</option>
              <option value="small">Small</option>
            </select>
          </div>
        </div>
        {/* dropdowns----------- */}
        <div className="mt-4">
          <div className="mb-4">
            <label className="w-100">
              <span className=" mb-2 d-flex justify-content-between align-items-center">
                <span className="me-2">Input type</span>
              </span>
            </label>
            <select
              disabled="true"
              className="form-select input-bn"
              value={NumberField?.inputType || ""}
              name="inputType"
              onChange={handleInputChange}
            >
              <option value="number">Numbers</option>
            </select>
          </div>
        </div>
        <div className="mb-4">
          <span>Options</span>
          <div className="mt-2 d-flex align-items-center">
            <div className="me-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={NumberField?.required || ""}
                onChange={handleInputChange}
                name="required"
                id="check-1"
              />
              <label className="form-check-label ms-1" htmlFor="check-1">
                Required
              </label>
            </div>
            <div className="me-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={NumberField?.hidden || ""}
                onChange={handleInputChange}
                name="hidden"
                id="check-10"
              />
              <label className="form-check-label ms-1" htmlFor="check-10">
                Hidden
              </label>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <label>
            <span className="d-flex mb-2 align-items-center">
              <span>Character Range</span>
            </span>
          </label>
          <div className=" d-flex">
            <div className="col-6 pe-1">
              <input
                placeholder="min value"
                defaultValue={NumberField?.minValue || ""}
                onBlur={handleInputChange}
                name="minValue"
                className="form-control input-bn col-5"
                type="number"
              />
            </div>
            <div className="col-6 ps-1">
              <input
                placeholder="max value"
                defaultValue={NumberField?.maxValue || ""}
                onBlur={handleInputChange}
                name="maxValue"
                className="form-control input-bn col-5"
                type="number"
              />
            </div>
          </div>
          {/* placeholder for errors */}
        </div>
        {/* text-area--------- */}
        <div className="mb-4">
          <div className="form-floating w-100 form-group">
            <textarea
              style={{ minHeight: "100px !important" }}
              defaultValue={NumberField?.instructions || ""}
              name="instructions"
              onBlur={handleInputChange}
              placeholder="Instructions for Users (max 100 char)"
              className="form-control input-bn"
              type="text"
              maxLength="100"
            />
            <label>
              <span className="d-flex align-items-center">
                <i className="iconify me-1 mr-1 fs-5" data-icon="mdi:text" />
                <span>Instructions for Users (max 100 char)</span>
              </span>
            </label>
          </div>
          {/* placeholder for errors */}
        </div>
        {!showingFieldId && (
          <span className="h6">
            System Field Id : {showingFieldId ? null : `${elementid}`}
          </span>
        )}
      </div>
      <ModalFooter
        ElementSettingData={NumberField}
        handleCloseModalClick={handleCloseModalClick}
        elementid={elementid}
      />
    </>
  );
};

export default NumberFieldSettings;
