import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom"; // For navigation
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS
import "ag-grid-community/styles/ag-theme-alpine.css"; // Theme CSS
import { gql, useQuery } from "@apollo/client";
import { Spin } from "antd";
import { useParams } from "react-router-dom";

const GetFieldsByTeamId = gql`
  query GetFieldsByTeamId($teamId: String) {
    GetFieldsByTeamId(team_id: $teamId) {
      field_id
      field_name
      field_type
      input_type
      settings
      field_sub_type
      opportunity_field_id
    }
  }
`;

const FormEntryAGridTable = ({ entries, pageSize = 10, teamid }) => {
  const navigate = useNavigate(); // React Router navigation hook
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState({}); // Tracks visibility state for each column
  const gridRef = useRef(null); // Create a ref for the grid
  const paramsid = useParams();

  const { data } = useQuery(GetFieldsByTeamId, {
    variables: { teamId: teamid },
  });

  // Memoize the field map for faster lookups
  const fieldMap = useMemo(() => {
    return (
      data?.GetFieldsByTeamId.reduce((map, field) => {
        map[field.field_id] = field?.field_name || field.settings?.label || field.field_id;
        return map;
      }, {}) || {}
    );
  }, [data]);

  const formatDate = (entryDate) => {
    const dateAsInt = parseInt(entryDate, 10);
    const newDate = new Date(dateAsInt);
    return newDate.toLocaleString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  };

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    sortable: true,
    filter: true,
    floatingFilter: true,
    resizable: true,
    autoHeight: false, // Ensures rows have a fixed height
  };

  function CustomGroupCellRenderer(params) {
    return params.value;
  }

  useEffect(() => {
    if (!entries || entries.length === 0 || !data?.GetFieldsByTeamId) return;

    const allKeys = new Set();

    entries.forEach((entry) => {
      Object.keys(entry).forEach((key) => {
        if (key !== "__typename" && key !== "form_id" && key !== "form_entry_id") {
          allKeys.add(key);
        }
      });

      if (entry.form_entry_data) {
        Object.keys(entry.form_entry_data).forEach((nestedKey) =>
          allKeys.add(`form_entry_data.${nestedKey}`)
        );
      }
    });

    const columnDefs = Array.from(allKeys).map((key) => {
      if (key === "entry_created_date") {
        return {
          field: key,
          headerName: "Created Date",
          filter: "agDateColumnFilter",
          sortable: true,
          valueFormatter: (params) => (params.value ? formatDate(params.value) : "-"),
          filterValueGetter: (params) => (params.value ? formatDate(params.value) : ""),
        };
      }

      const headerName = fieldMap[key] || key;

      return {
        field: key,
        headerName,
        filter: "agTextColumnFilter",
        cellRenderer: (params) =>
          params.value !== undefined && params.value !== null ? params.value : "-",
      };
    });

    const specialColumns = [
      {
        headerName: "Entry Number",
        valueGetter: "node.rowIndex + 1",
        sortable: false,
        filter: false,
      },
      columnDefs.find((col) => col.field === "entry_created_date"),
      {
        field: "InputField-opp-key",
        headerName: "Opp Key",
        cellRenderer: (params) => {
          const isValuePresent = params.value && params.value !== "-";
          // console.log("params", params.value);

          if (isValuePresent) {
            return (
              <button
                onClick={() => navigate(`/forms/opportunities/${params.value}?id=${paramsid.id}`)}
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  color: "blue",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                View Opportunities
              </button>
            );
          } else {
            return <span style={{ display: "flex", justifyContent: "center" }}>-</span>;
          }
        },
        sortable: false,
        filter: false,
      },
      columnDefs.find((col) => col.field === "InputField-Follow-up-boss-lead-id"),
    ].filter(Boolean);

    const otherColumns = columnDefs.filter(
      (col) =>
        !["entry_created_date", "InputField-opp-key", "InputField-Follow-up-boss-lead-id"].includes(
          col.field
        )
    );

    const finalColumnDefs = [
      ...specialColumns,
      ...otherColumns,
      {
        headerName: "Form Entries",
        field: "form_entry_action",
        cellRenderer: (params) => {
          return (
            <button
              onClick={() =>
                navigate(`/forms/${params.data.form_id}/entries/${params.data.form_entry_id}`)
              }
              style={{
                border: "none",
                backgroundColor: "transparent",
                color: "blue",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              View Entry
            </button>
          );
        },
        sortable: false,
        filter: false,
      },
    ];

    const visibilityState = finalColumnDefs.reduce((acc, col) => {
      acc[col.field] = true; // Default to visible
      return acc;
    }, {});

    const mappedRows = entries.map((entry) => {
      const flatEntry = { ...entry };

      if (entry.form_entry_data) {
        Object.keys(entry.form_entry_data).forEach((nestedKey) => {
          flatEntry[`form_entry_data.${nestedKey}`] = entry.form_entry_data[nestedKey];
        });
      }

      finalColumnDefs.forEach((col) => {
        const key = col.field;
        if (key && (flatEntry[key] === undefined || flatEntry[key] === null)) {
          flatEntry[key] = "-";
        }
      });

      return flatEntry;
    });

    setVisibleColumns(visibilityState);
    setColumnDefs(finalColumnDefs);
    setRowData(mappedRows);
  }, [entries, fieldMap, navigate]);

  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
      },
    ],
  };

  const filteredColumnDefs = columnDefs.filter((col) => visibleColumns[col.field]);

  const exportToCsv = () => {
    if (gridRef.current) {
      gridRef.current.api.exportDataAsCsv();
    }
  };

  return (
    <div className="ag-grid-container">
      <div className="d-flex mb-3">
        <button
          className="btn btn-primary d-flex align-items-center"
          onClick={exportToCsv}
          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <i
            className="bx bx-download"
            style={{
              fontSize: "1rem",
              marginRight: "0.5rem",
              lineHeight: "1",
            }}
          ></i>
          Export to CSV
        </button>
      </div>

      {rowData.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
            paddingTop: "20px",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div className="ag-theme-alpine" style={{ height: 600, width: "100%" }}>
          <AgGridReact
            ref={gridRef} // Attach the ref to the grid
            rowData={rowData}
            columnDefs={filteredColumnDefs}
            pagination={true}
            paginationPageSize={pageSize}
            defaultColDef={defaultColDef}
            sideBar={sideBar}
            frameworkComponents={{
              agGroupCellRenderer: CustomGroupCellRenderer,
            }}
            rowBuffer={20}
            domLayout="normal"
            animateRows={true}
            rowHeight={40}
          />
        </div>
      )}
    </div>
  );
};

export default FormEntryAGridTable;
