export const ClosedFormTemplatt=[
    {
        "elementid": "InputField-opp_last_name",
        "type": "InputField",
        "name": "Last Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Last Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "FreeText-Transaction Data",
        "type": "FreeText",
        "name": "Transaction Data",
        "settings": {
            "label": "Transaction Data",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "FreeText-address",
        "type": "FreeText",
        "name": "Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address",
        "type": "InputField",
        "name": "Address-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Street Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address2",
        "type": "InputField",
        "name": "Address-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_city",
        "type": "InputField",
        "name": "Address-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-City",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_state",
        "type": "InputField",
        "name": "Address-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-State",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_postal_code",
        "type": "InputField",
        "name": "Address-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Zip Code",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-opp_country",
        "type": "DropDown",
        "name": "Address-Country",
        "field_sub_type": "opportunity",
        "settings": {
            "options": [
                {
                    "id": "8030",
                    "value": "United States",
                    "isCheck": false
                },
                {
                    "id": "0755",
                    "value": "Canada",
                    "isCheck": false
                }
            ],
            "label": "Address-Country",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-transaction_amount",
        "type": "NumberField",
        "name": "Transaction Amount",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Transaction Amount",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-buyer_agent_commission_percent",
        "type": "NumberField",
        "name": "Buyer Agent Commission Percent",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Buyer Agent Commission Percent",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-listing_agent_commission_percent",
        "type": "NumberField",
        "settings": {
            "label": "Listing Agent Commission Percent",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Listing Agent Commission Percent"
    },
    {
        "elementid": "FreeText-Transaction_Date",
        "type": "FreeText",
        "name": "Transaction Date",
        "settings": {
            "label": "Transaction Date",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-opp_settlement_date",
        "type": "Date",
        "name": "Settlement Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Settlement Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "TextArea-Notes",
        "type": "TextArea",
        "name": "Notes",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Notes",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp-key",
        "type": "InputField",
        "name": "Opp Key",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Opp Key",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-Follow-up-boss-lead-id",
        "type": "InputField",
        "name": "Follow Up Boss Lead ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Follow Up Boss Lead ID",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    }
]

export const ClosedFormConditions=[]
