import React, { useContext } from "react";
import { ElementsSettingsWidget } from "./ElementsSettingsWidget";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { BoardDeleteHandler } from "../../BoardHandlerFunctions";
import { HeadingUi } from "./HeadingUi";

export const DndHeadingUi = ({
  elementid,
  element,
  oppFields,
  setDeleteField,
  Elementtype,
}) => {
  const { board, setboard } = useContext(boardConetxt);
  const ElementSetting = board?.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.settings;
  const { position } = ElementSetting;

  return (
    <div
      className={`fm-comp ${Elementtype}`}
      style={{ textAlign: `${position}` }}
    >
      <ElementsSettingsWidget
        setDeleteField={setDeleteField}
        elementid={elementid}
        board={board}
        setboard={setboard}
        BoardDeleteHandler={BoardDeleteHandler}
        element={element}
      />
      <HeadingUi elementid={elementid} oppFields={oppFields} />
    </div>
  );
};
