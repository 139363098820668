import { ElementsSettingsWidget } from "./ElementsSettingsWidget";
import { BoardDeleteHandler } from "../../BoardHandlerFunctions";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { useContext } from "react";
import { EmailUi } from "./EmailUi";

export const DnDEmail = ({
  elementid,
  element,
  oppFields,
  setDeleteField,
  Elementtype,
}) => {
  const { board, setboard } = useContext(boardConetxt);
  return (
    <div className={`fm-comp ${Elementtype}`} id={elementid}>
      <ElementsSettingsWidget
        setDeleteField={setDeleteField}
        BoardDeleteHandler={BoardDeleteHandler}
        elementid={elementid}
        board={board}
        setboard={setboard}
        element={element}
      />
      <EmailUi elementid={elementid} oppFields={oppFields} />
    </div>
  );
};
